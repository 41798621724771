/* ==========================================================================
   #RESPONSIVE_STATE_INDICATOR
   ========================================================================== */

.u-state-indicator {
  position: absolute;
  top: -999em;
  left: -999em;
}

@mixin inuit-state($state) {
  .u-state-indicator:before { content: '#{$state}'; }
}

@include inuit-state(phone);

@if (variable-exists(mq-breakpoints)) {
  @each $inuit-bp-name, $inuit-bp-value in $mq-breakpoints {
    @include mq($from: $inuit-bp-name) {
      @include inuit-state($inuit-bp-name);
    }
  }
}
