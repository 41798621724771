/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 * 5. For button inputs like button, input.
 * 7. For absolute pseudo borders to back and still visible.
 */

.c-btn {
  position: relative;
  z-index: 10; /* [7] */

  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: .05em;
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  border: none; /* [6] */
  padding: 6px 23px 7px;
  transition: $global-transition-links;
  border-radius: 100px;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
  }
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  background-color: $color-primary;

  &,
  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }

  @include mq($from: desktop){
    &:hover,
    &:focus {
      background-color: lighten($color-primary, 20%);
    }
  }

}

.c-btn--secondary {
  background-color: $color-secondary;

  &,
  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }

  @include mq($from: desktop){
    &:hover,
    &:focus {
      background-color: $color-primary;
    }
  }

}

.c-btn--white {
  background-color: #fff;

  &,
  &:hover,
  &:active,
  &:focus {
    color: $color-body;
  }

  @include mq($from: desktop){
    &:hover,
    &:focus {
      background-color: darken(#fff, 20%);
    }
  }

}

/* Size variants
   ========================================================================== */

.c-btn--small {
  // padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
}

.c-btn--large {
  // padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
}

/* Ghost
   ========================================================================== */

.c-btn--ghost {
  color: inherit;
  background-color: transparent;
  // box-shadow: inset 0 0 0 1px currentColor;
  box-shadow: inset 0 0 0 1px $color-secondary;

  @include mq($from: desktop){
    &:hover,
    &:focus {
      color: $color-primary;
      background-color: #fff;
      box-shadow: inset 0 0 0 0 $color-secondary, 0 0 0 3px $color-primary, 0 0 0 6px #fff, 0 0 50px rgba(#000, .5);
    }
  }
}

/* Action
   ========================================================================== */

.c-btn--action {
  overflow: hidden;
  border-radius: 0;
  font-size: 17px;
  padding: 28px 0;
}

.c-btn--action .c-btn__text {
  position: relative;
  display: inline-block;
  padding-left: 25px;
  padding-right: 25px;
}

.c-btn--action .c-btn__text:before,
.c-btn--action .c-btn__text:after {
  position: absolute;
  top: 50%;

  content: "";
  display: block;
  width: 1000px;
  height: 1px;
  background-color: currentColor;
}

.c-btn--action .c-btn__text:before {
  right: 100%;
}

.c-btn--action .c-btn__text:after {
  left: 100%;
}

/* Others
   ========================================================================== */

.c-btn--block {
  display: block;
  width: 100%;
}

/* Icon
   ========================================================================== */

.c-btn__icon {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
}

.c-btn__icon:first-child:not(:last-child) {
  margin-right: 5px;
}

.c-btn__icon:last-child:not(:first-child) {
  margin-left: 5px;
}

/* Responsive
   ========================================================================== */

@include mq($from: tablet) {

  .c-btn {
    padding: 9px 23px;
    font-size: 18px;
  }

  .c-btn--action {
    padding: 80px 0;
    font-size: 26px;
    line-height: 1.5;
  }

  .c-btn--action .c-btn__text {
    padding-left: 90px;
    padding-right: 90px;
  }

  .c-btn--large {
    font-size: 22px;
    line-height: 1.5;
    padding: 13px 13px 14px;
  }


}
