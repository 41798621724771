/* ==========================================================================
   #LEAD
   ========================================================================== */

.c-lead {
  font-size: 18px;
  line-height: 26px;
}

@include mq($from: tablet){
  .c-lead {
    margin-bottom: 40px;

    font-size: 22px;
    line-height: 32px;
  }
}
