/* ==========================================================================
   #LANG_MENU
   ========================================================================== */

.c-lang-menu .o-menu__item {
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
}

.c-lang-menu .o-menu__item:not(:first-child) {
  margin-left: 25px;
}

.c-lang-menu .o-menu__item:not(.is-current) {
  opacity: .5;
}

@include mq($from: tablet){

  .c-lang-menu .o-menu__item {
    font-weight: 700;
    font-size: 16px;
    text-transform: none;
    letter-spacing: .1em;
  }

  .c-lang-menu .o-menu__item:not(:first-child) {
    margin-left: 40px;
  }

}
