/* ==========================================================================
   #DESKTOP_MENU
   ========================================================================== */

.c-desktop-menu {
  display: inline-block;
  white-space: nowrap;

  text-align: left;
}

.c-desktop-menu .o-menu__item {
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: .1em;
  white-space: normal;
}

.c-desktop-menu .o-menu__item--level-0 {
  display: inline-block;
}

.c-desktop-menu .o-menu__item--level-0 > .o-menu__link {
  height: 90px;
  padding: 34px 20px;
  display: block;
}

.c-desktop-menu .o-menu__list--sub {
  background-color: $color-primary;
  border: 1px solid rgba(255,255,255,0.05);
}

.c-desktop-menu .o-menu__list--sub .o-menu__item:not(:first-child) {
  border-top: 1px solid rgba(255,255,255,0.05);
}

.c-desktop-menu .o-menu__list--sub .o-menu__link {
  display: block;
  padding: 15px 20px;
  background-color: $color-primary;
  font-size: 13px;
}

.is-scrolled.is-scrolled--to-top .c-header--sticky .c-desktop-menu .o-menu__list--sub {
  //border: 1px solid $color-secondary;
  //border-top-color: #333;
}
