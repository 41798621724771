/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * 1. Ensure buttons looks clickable.
 */

button:not([disabled]) {
  cursor: pointer; /* [1] */
}

button:focus {
  outline: none;
}
