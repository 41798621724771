/* ==========================================================================
   #COMMENTS-CAROUSEL-ROW
   ========================================================================== */

.c-comments-carousel-row {
  overflow: hidden;
}

.c-comments-carousel-row__inner {
  position: relative;
}

.c-comments-carousel-row__button {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: 0;
}

.c-comments-carousel-row .flickity-page-dots {
  text-align: left;
  padding-left: 5px;
  bottom: 14px;
  display: inline-block;
  width: auto;
}

.c-comments-carousel-row .flickity-viewport {
  overflow: visible;
}

/* Responsive
   ========================================================================== */

@include mq($from: $inuit-mobile-spacing-breakpoint){

  .c-comments-carousel-row .flickity-page-dots {
    padding-left: 23px;
  }

}

@include mq($from: tablet){
  .c-comments-carousel-row .flickity-page-dots {
    left: 40%;
    padding-left: 0;
    margin-left: -4px;
  }

  .c-comments-carousel-row__button {
    bottom: auto;
    top: 0;
  }

  .c-comments-carousel-row .c-carousel__item {
    transition: $global-transition;
  }

  .c-comments-carousel-row .c-carousel__item[aria-hidden="true"] {
    opacity: 0;
  }
}


@include mq($from: wide){
  .c-comments-carousel-row .flickity-page-dots {
    left: 50%;
    margin-left: -28px;
    bottom: 100px;
  }

  .c-comments-carousel-row .c-carousel--layout .c-carousel__item {
    padding-bottom: 0;
  }
}
