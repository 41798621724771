/* ==========================================================================
   #STEPS
   ========================================================================== */

.c-steps {
  counter-reset: c-steps;
}

.c-steps__item {
  counter-increment: c-steps;
}

.c-steps__body {
  position: relative;
  padding-top: 82px;
  padding-bottom: 30px;

  //max-width: 335px;
  //margin-left: auto;
  //margin-right: auto;
}

.c-steps__body:before {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;


  content: "";
  display: block;
  width: 6px;
  margin-left: -3px;

  background-color: #e8e8e8;
}

.c-steps__item:last-child .c-steps__body:before {
  bottom: 30px;
}

.c-steps__content {
  position: relative;
  z-index: 10;
  padding: 30px 20px 44px;

  border-radius: $global-radius;
  background-color: #fff;

  text-align: center;

  box-shadow: 0 15px 60px rgba(0, 0, 0, .1);
}

.c-steps__content:before {
  position: absolute;
  left: 50%;
  bottom: 100%;

  content: "";
  display: block;
  margin-left: -13px;

  border: 13px solid transparent;
  border-top-width: 0;
  border-bottom-width: 25px;
  border-bottom-color: #fff;
}

.c-steps__content:after {
  position: absolute;
  left: 50%;
  top: -82px;

  content: "0" counter(c-steps);
  display: block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  margin-left: (44px / -2);

  border-radius: 50%;

  font-weight: 700;
  font-size: 16px;

  color: #fff;
  background-color: $color-secondary;
}

.c-steps__image {
  margin-bottom: 20px;
  width: 60px;
  height: 60px;
}

.c-steps__title {
  margin-bottom: 10px;

  font-weight: 400;
  font-size: 22px;
  line-height: 28px;

  color: $color-primary;
}

.c-steps__description {
  font-size: 16px;
  line-height: 22px;
}


/* Responsive
   ========================================================================== */

@include mq($from: tablet){

  .c-steps__body {
    padding-bottom: 0;
  }

  .c-steps__body:before {
    display: block;
    width: calc(100% + 15px);
    height: 3px;
    bottom: auto;
    top: (44px / 2);
    left: auto;
    right: 50%;
  }

  .c-steps__item:first-child .c-steps__body:before {
    display: none;
  }

}

@include mq($from: wide){

  .c-steps__body {
    padding-top: 104px;
  }

  .c-steps__body:before {
    top: (64px / 2);
  }

  .c-steps__content:after {
    top: -104px;

    width: 64px;
    height: 64px;
    line-height: 64px;
    margin-left: (64px / -2);
  }

  .c-steps__description {
    font-size: 18px;
    line-height: 28px;
  }

}





