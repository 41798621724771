/* ==========================================================================
   #SITE_ANNOUNCEMENT
   ========================================================================== */

.c-site-announcement {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;

  display: block;
  padding: 20px;

  text-align: center;

  font-size: 18px;
  line-height: 26px;

  color: $color-primary;
  background-color: #fff;

  transform: translateY(-100%);

  transition: $global-transition;
}

$size: 38px;
$icon-size: 18px;
$icon-padding: ($size - $icon-size) / 2;

.c-site-announcement__close {
  position: absolute;
  right: 0;
  top: 0;

  display: block;
  width: $size;
  height: $size;
  padding: 0;
  border: none;
  background: none;

  color: $color-primary;
}

.c-site-announcement__close-icon {
  width: 100%;
  height: 100%;
  padding: $icon-padding;
}

.c-site-announcement__content > *:last-child {
  margin-bottom: 0;
}

.c-site-announcement .c-site-announcement__link {
  margin-left: 10px;
  margin-right: 10px;
}

html.is-site-announcement-active .c-site-announcement {
  box-shadow: 0 2px 3px rgba(0, 0, 0, .07);
}


@include mq($from: tablet){
  .c-site-announcement {
    font-size: 20px;
    line-height: 30px;
  }

  $size: 70px;
  $icon-size: 22px;
  $icon-padding: ($size - $icon-size) / 2;

  .c-site-announcement__close {
    width: $size;
    height: $size;
  }

  .c-site-announcement__close-icon {
    padding: $icon-padding;
  }
}

@include mq($from: desktop){
  .c-site-announcement__close {
    transition: $global-transition;
  }

  .c-site-announcement__close:hover {
    transform: rotate(90deg);
  }
}
