/* ==========================================================================
   #LAYERS
   ========================================================================== */

.c-layers,
.c-layers__item {
  position: relative;
  z-index: 10;

  display: block;
}

.c-layers__item--bg {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

$icon-size: 20px;
$icon-bg-size: 50px;
$icon-border-size: 1px;
$icon-padding: (($icon-bg-size - (2 * $icon-border-size) - $icon-size) / 2);

.c-layers__icon {
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: block;
  width: $icon-bg-size;
  height: $icon-bg-size;
  margin-left: auto;
  margin-right: auto;
  padding: $icon-padding;
  border: $icon-border-size solid #fff;
  border-radius: 50%;
}

.c-layers__icon .icon {
  width: $icon-size;
  height: $icon-size;
  fill: #fff;
}

.c-layers__item--hover {
  position: absolute;
  z-index: 15;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(#202021, .6);
  transition: $global-transition;
}

.c-layers:hover .c-layers__item--hover {
  background-color: rgba($color-primary, .8);
}
@include mq($from: tablet){

  $icon-size: 40px;
  $icon-bg-size: 100px;
  $icon-border-size: 2px;
  $icon-padding: (($icon-bg-size - (2 * $icon-border-size) - $icon-size) / 2);

  .c-layers__icon {
    width: $icon-bg-size;
    height: $icon-bg-size;
    padding: $icon-padding;
    border: $icon-border-size solid #fff;
  }

  .c-layers__icon .icon {
    width: $icon-size;
    height: $icon-size;
  }
}
