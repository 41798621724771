/* ==========================================================================
   #TIME
   ========================================================================== */

.c-time {
  display: block;

  margin-bottom: 38px;

  color: $color-secondary;

  font-size: 16px;
  line-height: 22px;
}
