.c-responsive-image,
.c-responsive-image__img {
  display: inline-block;
  vertical-align: top;
}

//.c-responsive-image__img {
//  width: 100%;
//  height: auto;
//}

.c-responsive-image--cover {
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  // transition: $global-transition-fastest;
}

.c-responsive-image--cover,
.c-responsive-image--cover .c-responsive-image__img {
  opacity: 0;
}

.c-responsive-image.is-loaded {
  opacity: 1;
}

