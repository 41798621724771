/* ==========================================================================
   #BREADCRUMBS */

.c-breadcrumbs {
  position: relative;
  padding-left: 34px;

  font-size: 11px;
  line-height: 1.5;

  color: #fff;

  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.c-breadcrumbs__list {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;

  white-space: nowrap;
}

.c-breadcrumbs__item {
  display: inline-block;
}

.c-breadcrumbs__item:not(:first-child) {
  padding-left: 22px;
}

.c-breadcrumbs__text {
  position: relative;
  display: inline-block;
  padding: 10px 0;
}

.c-breadcrumbs__item + .c-breadcrumbs__item .c-breadcrumbs__text:before {
  position: absolute;
  left: -15px;
  top: 50%;

  content: "";
  display: block;
  width: 6px;
  height: 6px;

  border-right: 2px solid;
  border-bottom: 2px solid;

  transform: rotate(-45deg) translateY(-50%);
}

.c-breadcrumbs__back {
  position: absolute;
  left: 0;
  top: 50%;

  display: inline-block;
  margin: 0;
  margin-top: ($back-button-size-mobile / -2);
  padding: 0;

  background: none;
  border: none;

  width: $back-button-size-mobile;
  height: $back-button-size-mobile;

  border-radius: 50%;

  color: inherit;
}

.c-breadcrumbs__back:before {
  position: absolute;
  left: 0;
  top: 0;

  content: "";
  display: block;
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  border-radius: 50%;
  border: 1px solid;
}

.c-breadcrumbs__back-icon {
  width: 100%;
  height: 100%;
  padding: 22%;

  fill: currentColor;
}

@include mq($from: tablet){

  .c-breadcrumbs {
    padding-left: 45px;
    font-size: 14px;
  }

  .c-breadcrumbs__back {
    margin-top: ($back-button-size-desktop / -2);
    width: $back-button-size-desktop;
    height: $back-button-size-desktop;
  }

}
