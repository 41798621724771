/* ==========================================================================
   #MAGZINE_ISSUES
   ========================================================================== */

.c-magazine-issues .c-simple-image {
  margin-bottom: 10px;
}

.c-magazine-issues .c-simple-image:not(.c-simple-image--fixed) {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

@include mq($from: tablet){
  .c-magazine-issues .c-simple-image--fixed .c-simple-image__picture {
    margin-bottom: 32px;
  }
}
