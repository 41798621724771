/* ==========================================================================
   #GROUP_OPACITY
   ========================================================================== */

/* Group Opacity Hover
   ========================================================================== */

@include mq($from: desktop){

  .o-group-opacity-hover > * {
    transition: $global-transition;
  }

  .o-group-opacity-hover:not(.o-group-opacity-hover--js-improved):hover > *:not(:hover),
  .o-group-opacity-hover--js-improved.is-hover > *:not(:hover) {
    opacity: .4;
  }

}
