/* ==========================================================================
   #SIDEBAR_LAYOUT
   ========================================================================== */

@include mq($from: tablet){

  $sidebar-width: (270px + 30px);
  $sidebar-gutter: (50px - 30px);

  .o-sidebar-layout__sidebar {
    width: $sidebar-width;
    margin-left: $sidebar-gutter;
  }

  .o-sidebar-layout__main {
    width: calc(100% - #{$sidebar-width + $sidebar-gutter});
  }

  .o-sidebar-layout--left-sidebar {
    direction: rtl;
  }

  .o-sidebar-layout--left-sidebar .o-sidebar-layout__main,
  .o-sidebar-layout--left-sidebar .o-sidebar-layout__sidebar {
    direction: ltr;
  }

  .o-sidebar-layout--left-sidebar .o-sidebar-layout__sidebar {
    margin-left: 0;
    margin-right: $sidebar-gutter;
  }
}
