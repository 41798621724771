/* ==========================================================================
   #PAGE_HEADER_ALTERNATE */

.c-page-header-alternate {
  margin-bottom: 9px;
  padding-top: $header-height;
}

.c-page-header-alternate__breadcrumb {
  padding-top: 6px;
  padding-bottom: 4px;

  border-bottom: 1px solid $color-border;
}

.c-page-header-alternate__breadcrumb .c-breadcrumbs {
  color: $color-primary;
}


/* Responsive
   ========================================================================== */

@include mq($from: tablet){
  .c-page-header-alternate {
    padding-top: 38px;
    margin-bottom: (9px - 34px);
  }

  .c-page-header-alternate__breadcrumb {
    padding-bottom: 12px;
  }

  .c-page-header-alternate .c-breadcrumbs__back:hover {
    background-color: $color-primary;
    color: #fff;
  }

  .c-page-header-alternate .c-breadcrumbs__back:hover:before {
    border-color: transparent;
  }
}
