/* ==========================================================================
   #PANEL
   ========================================================================== */

.c-panel {
  position: relative;
  z-index: 10;

  box-shadow: 0 15px 60px rgba(0, 0, 0, .1);

  border-radius: $global-radius;

  background-color: #fff;
}

.c-panel__header {
  padding: 24px 30px;
  @include inuit-clearfix;
}

.c-panel__title {
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 8px;

  font-weight: 700;
  font-size: 22px;
  line-height: 1;

  color: $color-primary;
}

.c-panel__action {
  float: right;
}

.c-panel__body {
  padding: 19px 30px 39px;

  @include inuit-clearfix;
}

.c-panel__body .flickity-viewport {
  width: 100%;
}

.c-panel__body:not(:first-child) {
  border-top: 1px solid #f4f4f4;
}

/* Light
   ========================================================================== */

.c-panel--light {
  color: #fff;
  background-color: #091d70;
}

.c-panel.c-panel--light * {
  color: inherit;
}

.c-panel--light .c-panel__action {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #fff;
}

.c-panel--light .c-panel__body:not(:first-child) {
  padding-top: 0;
  border-top-color: transparent;
}

.c-panel--light .flickity-page-dots .dot {
  background-color: #fff;
}

/* Announcements
   ========================================================================== */

.c-panel--announcements {
  background-color: #008cff;
}

/* Layout
   ========================================================================== */

.c-panel .c-carousel--layout {
  margin-left: -30px;
  margin-right: -30px;
}

.c-panel .c-carousel--layout .c-carousel__item {
  padding-left: 30px;
  padding-right: 30px;
}

/* Decoration
   ========================================================================== */

.c-panel__decoration {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;

  // pointer-events: none;

  width: 270px;
  height: auto;

  border-bottom-right-radius: $global-radius;
}

//.c-panel--has-decoration {
//  padding-bottom: 47px;
//}

.c-panel--has-decoration .flickity-page-dots {
  width: auto;
  left: 23px;
}

//.c-panel--has-decoration .c-carousel--layout .c-carousel__item {
//  padding-bottom: (60px + 47px);
//}

/* Mobile Height Fix
   ========================================================================== */

.c-panel .c-entry {
  min-height: 277px;
}


@include mq($until: tablet){
}


/* Responsive
   ========================================================================== */

@include mq($from: tablet) {
  .c-panel__header {
    padding: 22px 50px;
  }

  .c-panel__title {
    font-size: 30px;
    line-height: 1.2;
  }

  .c-panel__action.c-btn {
    top: 7px;
    padding: 9px 23px;
    font-size: 18px;
  }

  .c-panel .c-carousel--layout .c-carousel__item {
    padding-top: 37px;
    padding-right: 50px;
    padding-bottom: 40px;
    padding-left: 50px;
  }

  .c-panel--has-decoration .flickity-page-dots {
    width: auto;
    left: 120px;
  }

  .c-panel .c-entry {
    min-height: 260px;
  }
}

@include mq($from: desktop){

  .c-panel__header {
    padding: 32px 50px;
  }

  .c-panel--light .c-panel__header {
    padding-bottom: 12px;
  }

  //.c-panel--has-decoration .c-carousel--layout .c-carousel__item {
  //  padding-bottom: (60px + 47px);
  //}

  .c-panel .c-entry__title {
    font-size: 24px;
    line-height: 30px;
  }

  .c-panel .flickity-page-dots {
    bottom: 8px;
  }

  .c-panel__decoration {
    width: 333px;
  }

}
