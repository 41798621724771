/* ==========================================================================
   #BOARD
   ========================================================================== */

.c-board {
  text-align: center;
}


/* Responsive
   ========================================================================== */

@include mq($until: tablet){

  .c-board .o-layout__item:first-child {
    margin-left: 30px;
    margin-right: 30px;
  }

}
