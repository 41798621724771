/**
 * Carousel
 */

/**
 * Flickity Overrides
 */

.flickity-page-dots-inner {
  list-style: none;
  margin: 0;
  padding: 0;
}

$flickity-arrow-size: 35px;
$flickity-arrow-icon-size: 10px;
$flickity-arrow-icon-size-percent: percentage($flickity-arrow-icon-size / $flickity-arrow-size);

$flickity-desktop-arrow-size: 48px;
$flickity-desktop-arrow-icon-size: 10px;
$flickity-desktop-arrow-icon-size-percent: percentage($flickity-desktop-arrow-icon-size / $flickity-desktop-arrow-size);

.flickity-prev-next-button {
  z-index: 50;
  width: $flickity-arrow-size;
  height: $flickity-arrow-size;
  background-color: $color-primary;
}

.flickity-prev-next-button:hover {
  background-color: $color-primary-hover;
}

.flickity-prev-next-button .flickity-button-icon {
  fill: #fff;

  left: ((100% - $flickity-arrow-icon-size-percent) / 2);
  top: ((100% - $flickity-arrow-icon-size-percent) / 2);
  width: $flickity-arrow-icon-size-percent;
  height: $flickity-arrow-icon-size-percent;
}

.flickity-page-dots {
  //position: static;
  //padding-top: 16px;
  bottom: 0;
}

.flickity-page-dots .dot {
  background-color: $color-primary;
  opacity: .2;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.flickity-page-dots .dot * {
  pointer-events: none;
}

.flickity-viewport {
  transition: $global-transition;
}

.flickity-button:disabled {
  opacity: 1;
}

.c-carousel[data-options*='"wrapAround":false'] .flickity-button:disabled {
  opacity: .3;
}

@include mq($from: tablet) {
  .flickity-prev-next-button {
    width: $flickity-desktop-arrow-size;
    height: $flickity-desktop-arrow-size;
  }

  .flickity-prev-next-button .flickity-button-icon {
    left: ((100% - $flickity-desktop-arrow-icon-size-percent) / 2);
    top: ((100% - $flickity-desktop-arrow-icon-size-percent) / 2);
    width: $flickity-desktop-arrow-icon-size-percent;
    height: $flickity-desktop-arrow-icon-size-percent;
  }

  .flickity-page-dots .dot {
    width: 16px;
    height: 16px;
  }
}

/**
 * c-carousel--dots-style-1
 */

$dot-clickable-area-size: 40px;
$dot-circle-size: 12px;

.c-carousel--dots-style-1 .flickity-page-dots .dot {
  position: relative;

  width: $dot-clickable-area-size;
  height: $dot-clickable-area-size;

  margin: 0;

  background-color: transparent;
  opacity: 1;
}

.c-carousel--dots-style-1 .flickity-page-dots .dot:before {
  position: absolute;
  left: 50%;
  top: 50%;

  content: "";
  display: block;
  width: $dot-circle-size;
  height: $dot-circle-size;
  margin-top: ($dot-circle-size / -2);
  margin-left: ($dot-circle-size / -2);

  background-color: rgba(#222, 0.2);

  border-radius: 50%;
}

.c-carousel--dots-style-1 .flickity-page-dots .dot.is-selected:before {
  background-color: $color-primary;
}

/**
 * c-carousel--dots-style-2
 */

$dot-clickable-area-size: 23px;
$dot-circle-size: 10px;

.c-carousel--dots-style-2 {

  .flickity-page-dots .dot {
    position: relative;

    width: $dot-clickable-area-size;
    height: $dot-clickable-area-size;

    margin: 0;

    background-color: transparent;
    opacity: 1;
  }

  .flickity-page-dots .dot:before {
    position: absolute;
    left: 50%;
    top: 50%;

    content: "";
    display: block;
    width: $dot-circle-size;
    height: $dot-circle-size;
    margin-top: ($dot-circle-size / -2);
    margin-left: ($dot-circle-size / -2);

    background-color: transparent;

    border-radius: 50%;
    border: 1px solid currentColor;

    transition: $global-transition;
  }

  .flickity-page-dots .dot.is-selected:before {
    background-color: currentColor;
    transform: scale(1.5);
  }

}


/**
 * Base
 */

.c-carousel img {
  width: 100%;
  height: auto;
}

.c-carousel.flickity-enabled {
  opacity: 1;
}

.c-carousel__item {
  position: relative;
  width: 100%;

  // text-align: center;

  overflow: hidden;
}

.c-carousel__figure {
  position: relative;
  z-index: 1;
}

.c-carousel__item--left-top-overlay .c-carousel__figure:before {
  position: absolute;
  z-index: 10;
  top: -35%;
  right: 35%;
  bottom: -20%;
  left: -50%;

  content: "";
  display: block;
  // background: radial-gradient(ellipse at center, rgba(0,172,238,1) 0%,rgba(0,172,238,0) 100%);
  // background: radial-gradient(ellipse at center, rgba(0,172,238,1) 0%,rgba(0,172,238,0) 50%);
  background: radial-gradient(ellipse at center, rgba(0, 172, 238, 0.9) 0%, rgba(0,172,238,0) 60%);
}

.c-carousel__heading {
  color: inherit;
}





/* Numbers
   ========================================================================== */

.c-carousel__page-numbers {
  position: absolute;
  right: 30px;
  bottom: 6px;
}

.c-carousel__page-total {
  font-size: 11px;
}

.c-carousel__page-total:before {
  content: "/";
  padding-left: 9px;
  padding-right: 5px;
}

.c-carousel__page-current {
  font-size: 25px;
}

.c-carousel__page-progress {
  position: absolute;
  left: 0;
  bottom: 0;

  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(#fff, .2);
}

.c-carousel__page-progress-inner {
  display: inline-block;
  vertical-align: top;
  width: 0;
  height: 100%;
  background-color: currentColor;
  transition: $global-transition;
}



/* Overlay
   ========================================================================== */

.c-carousel__item--overlay .c-carousel__figure:before {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: "";
  display: block;
  background-color: rgba(#202021, .6);
}



/* Hide controls
   ========================================================================== */

.c-carousel.has-one-page .flickity-page-dots{
  display: none;
}


/* Layout
   ========================================================================== */

.c-carousel--layout {
  margin: (-$inuit-mobile-spacing-unit / 2);
  margin-bottom: 0;
}

.c-carousel--layout .c-carousel__item {
  padding: $inuit-mobile-spacing-unit ($inuit-mobile-spacing-unit / 2) 60px;
}




@include mq($from: $inuit-mobile-spacing-breakpoint) {
  .c-carousel--layout {
    margin: -$inuit-global-spacing-unit;
    margin-bottom: 0;
  }

  .c-carousel--layout .c-carousel__item {
    padding: $inuit-global-spacing-unit $inuit-global-spacing-unit 60px;
  }
}

@include mq($from: tablet){

  .c-carousel--layout {
    margin: -$inuit-global-spacing-unit-small;
    margin-bottom: 0;
  }

  .c-carousel--layout .c-carousel__item {
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-small 60px;
  }

  .c-carousel--layout .flickity-prev-next-button.previous {
    left: -2px;
    //left: ($inuit-global-spacing-unit-small + 10);
  }

  .c-carousel--layout .flickity-prev-next-button.next {
    right: -2px;
    //right: ($inuit-global-spacing-unit-small + 10);
  }
}


/* Light
   ========================================================================== */

.c-carousel--light .flickity-page-dots .dot {
  background-color: #fff;
  opacity: .3;
}

.c-carousel--light .flickity-page-dots .dot.is-selected {
  opacity: 1;
}


/* .c-carousel--arrows-bottom-right
   ========================================================================== */

$arrow-gutter: 10px;

.c-carousel--arrows-bottom-right .flickity-prev-next-button {
  left: auto;
  right: 34px;
  top: auto;
  bottom: 40px;

  transform: none;
  margin: 0;
}

.c-carousel--arrows-bottom-right .flickity-prev-next-button.previous {
  margin-right: ($flickity-arrow-size + $arrow-gutter);
}

@include mq($from: tablet) {
  .c-carousel--arrows-bottom-right .flickity-prev-next-button.previous {
    margin-right: ($flickity-desktop-arrow-size + $arrow-gutter);
  }
}




/* .c-carousel--arrows-ghost
   ========================================================================== */

.c-carousel--arrows-ghost .flickity-prev-next-button {
  color: currentcolor;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px currentColor;
}

.c-carousel--arrows-ghost .flickity-prev-next-button .flickity-button-icon {
  fill: currentColor;
}

.c-carousel--arrows-ghost-next-primary .flickity-prev-next-button.next {
  color: $color-primary;
}



/* .c-carousel--arrows-primary
   ========================================================================== */

.c-carousel--arrows-primary .flickity-prev-next-button {
  border-radius: 0;
  background-color: $color-primary;
}

.c-carousel--arrows-primary .flickity-prev-next-button.next {
  background-color: #8aa5af;
}

.c-carousel--arrows-primary .flickity-prev-next-button.next:hover {
  background-color: lighten(#8aa5af, 10%);
}

.c-carousel--arrows-primary .flickity-prev-next-button.previous:hover {
  background-color: lighten($color-primary, 10%);
}

.c-carousel--arrows-primary .flickity-prev-next-button .flickity-button-icon {
  left: 35%;
  top: 35%;
  width: 30%;
  height: 30%;
}

@include mq($from: wide){
  .c-carousel--arrows-primary .flickity-prev-next-button {
    width: 90px;
    height: 90px;
  }

  .c-carousel--arrows-primary .flickity-prev-next-button .flickity-button-icon {
    left: 42%;
    top: 42%;
    width: 16%;
    height: 16%;
  }
}



/* .c-carousel--arrows-bottom
   ========================================================================== */

$gutter: 12px;

.c-carousel.c-carousel--arrows-bottom {
  text-align: center;
}

.c-carousel.c-carousel--arrows-bottom .flickity-prev-next-button {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  transform: none;
  margin: $gutter 0 0 0;

  // width: calc(50% - #{$gutter / 2});
}

.c-carousel.c-carousel--arrows-bottom .flickity-prev-next-button.next {
  margin-left: $gutter;
}

@include mq($from: tablet){
  .c-carousel.c-carousel--arrows-bottom .flickity-prev-next-button {
    margin-top: 24px;
    margin-bottom: -16px;
  }
}


/* .c-carousel--mobile-improved
   ========================================================================== */

@include mq($until: tablet){
  $gutter: 3px * 2;

  .c-carousel.c-carousel--mobile-improved .flickity-prev-next-button {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    transform: none;
    margin: $gutter 0 0 0;

    width: calc(50% - #{$gutter / 2});
  }

  .c-carousel.c-carousel--mobile-improved .flickity-prev-next-button.next {
    margin-left: $gutter;
  }
}

