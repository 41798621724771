/* ==========================================================================
   #SEARCH_RESULT
   ========================================================================== */

/* Results
   ========================================================================== */

.c-search-results__content a {
  font-weight: 400;
}

.c-search-results a:hover {
  color: $color-primary;
}

.c-search-results__content > *:last-child {
  margin-bottom: 0;
}

.c-search-results__content:not(:last-child) {
  margin-bottom: 20px;
}

.c-search-results__item {
  padding-top: 15px;
  padding-bottom: 15px;

  border-bottom: 1px solid #ebebeb;
}

.c-search-results__item:first-child {
  border-top: 1px solid #ebebeb;
}

.c-search-results__title {
  margin-bottom: 30px;

  font-size: 22px;
  line-height: 28px;

  color: inherit;
}

.c-search-results__more {
  color: $color-secondary;
}



/* Large
   ========================================================================== */

.c-search-results--large .c-search-results__item {
  padding-top: 35px;
  padding-bottom: 35px;
}



/* Responsive
   ========================================================================== */

@include mq($from: tablet){
  .c-search-results__title {
    margin-bottom: 15px;
  }

  .c-search-results__content:not(:last-child) {
    margin-bottom: 16px;
  }

  .c-search-results__item {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .c-search-results--large .c-search-results__item {
    padding-top: 46px;
    padding-bottom: 42px;
  }

}
