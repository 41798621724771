/* ==========================================================================
   #PAGE_NAVIGATION */

.c-page-headings .o-content {
  padding-top: 38px;
}

.c-page-headings__inner {
  position: relative;
  padding-top: 40px;
}

.c-page-headings__inner:before {
  position: absolute;
  left: 50%;
  top: 0;

  content: "";
  display: block;
  width: 1px;
  height: 25px;
  margin-left: -1px;

  background-color: #282829;
}

.c-page-heading {
  margin-bottom: 0;
  padding-bottom: 15px;
}

.c-page-navigation + .c-page-headings .o-content {
  padding-top: 4px;
}

@include mq($from: tablet){

  .c-page-headings .o-content {
    padding-top: 40px;
  }

  .c-page-navigation + .c-page-headings .o-content {
    padding-top: 10px;
  }

  .c-page-heading {
    padding-bottom: 32px;

    font-size: 34px;
    line-height: 44px;
  }

  .c-page-headings .c-lead {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  .c-page-headings__inner {
    padding-top: 80px;
  }

  .c-page-headings__inner:before {
    height: 50px;
  }

}
