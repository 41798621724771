/* ==========================================================================
   #BLOG
   ========================================================================== */


/* Items
   ========================================================================== */

.c-blog__items {
  margin-bottom: 15px;
}

@include mq($from: tablet){
  .c-blog__items {
    margin-bottom: 75px;
  }
}


/* Nav
   ========================================================================== */

.c-blog__nav {
  margin-top: -20px;
  margin-bottom: 32px;
}

@include mq($from: tablet){

  .c-blog__nav {
    margin-top: -30px;
    margin-bottom: 72px;

    text-align: center;
  }

  .c-blog__nav .o-menu--tags {
    margin-left: 0;
  }

}



/* Pagination
   ========================================================================== */

.c-blog__pagination {
  text-align: center;
}

.c-blog__pagination .o-menu--pagination {
  margin-left: 0;
}

