/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 32px;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 18px;
}



/**
 * Consistent indentation for lists.
 */

dd, ol, ul {
  margin-left: 32px;
}



/**
 * Font
 */

body,
h1, h2, h3, h4, h5, h6,
button,
input,
optgroup,
select,
textarea {
  font-family: $font-body;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  color: $color-heading;
}

@include mq($from: tablet) {
  address,
  h1, h2, h3, h4, h5, h6,
  blockquote, p, pre,
  dl, ol, ul,
  figure,
  hr,
  table,
  fieldset {
    margin-bottom: 30px;
    // margin-bottom: 43px;
  }

  dd, ol, ul {
    margin-left: 30px;
    // margin-left: 43px;
  }
}
