/**
 * Lightbox
 */

[data-toggle="lightbox"] * {
  pointer-events: none;
}

.c-lightbox {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  //right: 0;
  //bottom: 0;
  width: 100%;
  height: 100%;

  text-align: center;
  font-size: 0;

  color: $color-heading;
  background-color: rgba(#000, .8);

  overflow: hidden auto;

  opacity: 0;
  transition: opacity .6s ease-in-out;

  // cursor: zoom-out;
  cursor: url('../img/cursor-close-white.png'), auto;
}

.c-lightbox:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;

  pointer-events: none;
}

.c-lightbox.is-active {
  opacity: 1;
}

$preloader-size: 100px;
.c-lightbox.is-loading:after {
  position: absolute;
  left: 50%;
  top: 50%;

  content: "";
  display: block;
  width: $preloader-size;
  height: $preloader-size;
  margin-left: ($preloader-size / -2);
  margin-top: ($preloader-size / -2);

  border: 5px solid #fff;
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;

  animation: spin 1s linear 0s infinite;

  pointer-events: none;
}

.c-lightbox__content {
  position: relative;

  display: inline-block;
  max-width: 95%;
  margin: 0 auto;

  vertical-align: middle;

  text-align: left;

  font-size: $inuit-global-font-size;

  // background: #002239;

  opacity: 0;
  transition: $global-transition-600;

  cursor: auto;

  overflow: hidden;
}

.c-lightbox.is-loaded .c-lightbox__content {
  opacity: 1;
}

$action-icon-size: 11px;

.c-lightbox__action {
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  position: absolute;
  z-index: 2000;
  top: 17px;
  right: 15px;

  //top: 9px;
  //right: 18px;

  width: $action-mobile-size;
  height: $action-mobile-size;

  color: #fff;
  font-size: decimal-round($action-mobile-size * 44 / 50, 0);
  line-height: 1;
  vertical-align: top;
  font-family: sans-serif;

  background-color: $color-primary;

  transition: $global-transition;
}

.c-lightbox__action .icon {
  display: block;
  width: $action-icon-size;
  height: $action-icon-size;
  margin: auto;
  fill: #fff;
}

.c-lightbox__action * {
  pointer-events: none;
}

.c-lightbox__action:focus {
  outline: 0;
}

.c-lightbox__action--previous {
  right: auto;
  left: 15px;

  opacity: 0;
  visibility: hidden;

  transition: opacity .3s ease-in-out .3s, background .3s ease-in-out, visibility 0s ease .6s;
}

.c-lightbox__action--previous.is-active {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s ease-in-out .3s, background .3s ease-in-out, visibility 0s ease .3s;
}

.c-lightbox__action--previous .icon {
  transform: rotate(180deg);
}

/**
 * Iframe
 */

.c-lightbox.is-iframe .c-lightbox__content {
  width: 1400px;
}

/**
 * Off Canvas
 */

.c-lightbox--off-canvas {
  overflow: hidden;
}

.c-lightbox--off-canvas .c-lightbox__content {
  position: relative;
  z-index: 10;

  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 15px;

  color: #fff;
  background-color: #252088;
}

.c-lightbox--off-canvas .c-lightbox__content:before,
.c-lightbox--off-canvas .c-lightbox__content:after {
  position: absolute;
  z-index: 1;

  content: "";
  display: block;
}

.c-lightbox--off-canvas .c-lightbox__content:before {
  left: -100px;
  top: -100px;

  width: 422px;
  height: 422px;

  background: url(../img/off-canvas-bg.svg) scroll no-repeat 0 0;
  background-size: 100% 100%;
}

.c-lightbox--off-canvas .c-lightbox__content:after {
  right: -160px;
  bottom: -180px;

  width: 450px;
  height: 450px;

  background: url(../img/off-canvas-bg-glow.svg) scroll no-repeat 0 0;
  background-size: 100% 100%;
}

.c-lightbox--off-canvas .c-lightbox__action--previous {
  left: auto;
  right: (300px - $action-mobile-size - 15px);
}

.c-lightbox--off-canvas .c-lightbox__content {
  transform: translateX(0);
}

/* Right
   ========================================================================== */

.c-lightbox--right {
  text-align: right;
}

.c-lightbox--off-canvas.c-lightbox--right .c-lightbox__content {
  transform: translateX(100%);

  box-shadow: -30px 0 50px rgba(#000, .2);
}

.c-lightbox--off-canvas.c-lightbox--right.is-loaded .c-lightbox__content {
  transform: translateX(0);
}

.c-lightbox--off-canvas.c-lightbox--right.is-closing .c-lightbox__content {
  transform: translateX(100%);
}

/* Left
   ========================================================================== */

.c-lightbox--left {
  text-align: left;
}

.c-lightbox--off-canvas.c-lightbox--left .c-lightbox__content {
  transform: translateX(-100%);

  box-shadow: 30px 0 50px rgba(#000, .2);
}

.c-lightbox--off-canvas.c-lightbox--left.is-loaded .c-lightbox__content {
  transform: translateX(0);
}

.c-lightbox--off-canvas.c-lightbox--left.is-closing .c-lightbox__content {
  transform: translateX(-100%);
}

/* Right at Tablet
   ========================================================================== */

@include mq($from: tablet) {
  .c-lightbox--right\@tablet {
    text-align: right;
  }

  .c-lightbox--off-canvas.c-lightbox--right\@tablet .c-lightbox__content {
    transform: translateX(100%);
  }

  .c-lightbox--off-canvas.c-lightbox--right\@tablet.is-loaded .c-lightbox__content {
    transform: translateX(0);
  }

  .c-lightbox--off-canvas.c-lightbox--right\@tablet.is-closing .c-lightbox__content {
    transform: translateX(100%);
  }
}

// inner

.c-off-canvas {
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;

  left: 0;
  right: 0;

  overflow: hidden;
  //overflow: auto;
  //-webkit-overflow-scrolling: touch;
}

.c-off-canvas__nested {
  position: absolute;
  // top: 180px;
  top: 115px;
  bottom: 0;

  width: 100%;

  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.c-off-canvas__nested.has-scrollbar {
  box-shadow: inset 0 1px 0 rgba(#fff, .075), inset 0 10px 10px rgba(#000, .2);
}

.c-off-canvas .o-menu__list--sub {
  padding-top: 53px;
}

.c-off-canvas__previous {
  background: none;
  border: none;
  margin: 0;

  position: absolute;
  z-index: 2000;
  top: 132px;
  left: 15px;

  display: inline-block;
  padding: 10px 16px;

  color: $color-secondary;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  vertical-align: top;

  transition: $global-transition;

}

.c-off-canvas__previous .icon {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -5px;

  display: block;
  width: 10px;
  height: 10px;
  fill: $color-secondary;
}

.c-off-canvas__previous * {
  pointer-events: none;
}

.c-off-canvas__previous:focus {
  outline: 0;
}

.c-off-canvas__previous {
  opacity: 0;
  visibility: hidden;

  transition: opacity .3s ease-in-out, visibility 0s ease .3s;
}

.c-off-canvas__previous.is-active {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s ease-in-out .3s, visibility 0s ease .3s;
}

.c-off-canvas__previous .icon {
  transform: rotate(180deg);
}

.c-off-canvas__body {
  position: absolute;
  top: 0;
  bottom: 0;

  width: 100%;

  display: flex;
  flex-direction: column;
  // justify-content: center;

  // padding-bottom: (75px - 15); /* for bottom social icons */

  transition: $global-transition;
}

.c-off-canvas__body > .o-menu {
  padding-top: 15px;
  padding-bottom: 15px;
}

.c-off-canvas__body .o-menu__item {
  // font-size: 15px;
  // font-size: 19px; // mevcut genişliğe sığmadı
  font-size: 16px;

  line-height: 1.5;
  letter-spacing: .1em;

  text-transform: uppercase;
  font-weight: 700;
}

.c-off-canvas__body .o-menu__list--sub .o-menu__item:not(.o-menu__item--heading) {
  text-transform: none;
  font-weight: 400;
}

.c-off-canvas__body .o-menu__link {
  display: block;
  overflow: hidden;

  padding: 6px 20px 7px;

  transition: $global-transition;
}

.c-off-canvas__body .o-menu__item--has-caret > .o-menu__link {
  padding-right: 48px;
}

.c-off-canvas__body .o-menu__text {
  position: relative;
  display: inline-block;
}

//.c-off-canvas__body .o-menu__text:before,
//.c-off-canvas__body .o-menu__text:after {
//  position: absolute;
//  top: 50%;
//
//  content: "";
//  display: block;
//  width: 50px;
//  height: 1px;
//
//  background-color: $color-primary;
//
//  opacity: 0;
//
//  transform: scaleX(0);
//
//  transition: $global-transition;
//}
//
//.c-off-canvas__body .o-menu__text:before {
//  right: 100%;
//  margin-right: 20px;
//
//  transform-origin: 100% 0;
//}
//
//.c-off-canvas__body .o-menu__text:after {
//  left: 100%;
//  margin-left: 20px;
//
//  transform-origin: 0 0;
//}

.c-off-canvas__body .o-menu__item:hover > .o-menu__link,
.c-off-canvas__body .o-menu__item.is-active > .o-menu__link {
  color: $color-secondary;
}

//.c-off-canvas__body .o-menu__item:hover > .o-menu__link > .o-menu__text:before,
//.c-off-canvas__body .o-menu__item.is-active > .o-menu__link > .o-menu__text:before,
//.c-off-canvas__body .o-menu__item:hover > .o-menu__link > .o-menu__text:after,
//.c-off-canvas__body .o-menu__item.is-active > .o-menu__link > .o-menu__text:after {
//  opacity: 1;
//  transform: scaleX(1);
//}

.c-off-canvas__bottom {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;

  padding-bottom: 14px;
}

.c-off-canvas__lang-menu {
  position: absolute;
  right: 20px;
  top: 22px;
}

.c-off-canvas__member-menu {
  position: absolute;
  left: 20px;
  top: 78px;
}

/**
 * Close
 */

.c-lightbox__action--close {
  opacity: 0;
  transform: rotate(90deg);
  transition: all 300ms ease-in-out 600ms;
}

.c-lightbox.is-loaded .c-lightbox__action--close {
  opacity: 1;
  transition-delay: 600ms;
  transform: rotate(0deg);
}

.c-lightbox.is-closing .c-lightbox__action--close {
  opacity: 0;
  transition-delay: 0ms;
  transform: rotate(90deg);
}

.c-off-canvas__content {
  text-align: left;
  width: 300px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

html.is-off-canvas-active {
  overflow: hidden;
}

/* Custom Close
   ========================================================================== */

.c-lightbox--off-canvas .c-lightbox__action {
  display: none !important;
}

.c-lightbox--off-canvas .c-lightbox__action {
  display: none !important;
}

/* Content close
   ========================================================================== */

.c-lightbox--inner-close .c-lightbox__content {
  overflow: visible;
  box-shadow: 0 0 0 10px $color-primary;
}

.c-lightbox__content .c-lightbox__action--close {
  right: 5px;
  top: (($action-mobile-size / -2) - 10);

  border-radius: 50%;
}

@include mq($from: desktop){

  .c-lightbox__content .c-lightbox__action--close {
    right: (($action-mobile-size / -2) - 10);
  }

}


/* Responsive
   ========================================================================== */

@include mq($from: tablet) {

  .c-lightbox--off-canvas .c-lightbox__content {
    width: 640px;
  }

  .c-off-canvas__lang-menu {
    right: auto;
    left: 50px;
    top: 32px;
  }

  .c-off-canvas__member-menu {
    display: none;
  }

  .c-off-canvas__nested {
    top: $header-height-desktop;
  }

  .c-off-canvas__body .o-menu__item {
    font-size: 22px;
  }

  .c-off-canvas__body .o-menu__link {
    padding: 13px 50px;
  }

  .c-off-canvas__body > .o-menu {
    //padding-top: 52px;
    //padding-bottom: 52px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .c-off-canvas__body .o-menu__caret {
    right: (28px + 5);
    margin-top: (8px / -5);

    width: 10px;
    height: 10px;
  }

  .c-off-canvas .o-menu__list--sub {
    padding-top: 111px;
  }

  .c-off-canvas__previous {
    top: 152px;
    left: 49px;

    font-size: 18px;
  }

  .c-off-canvas__body .o-menu__list--sub .o-menu__item:not(.o-menu__item--heading) {
    font-size: 20px;
  }

  .c-lightbox--off-canvas .c-lightbox__content:before {
    left: -50px;
  }

  .c-lightbox--off-canvas .c-lightbox__content:after {
    right: -100px;
    bottom: -150px;
  }


}

@include mq($from: desktop) {

  .c-off-canvas__nested {
    @include browser-scrollbar();
  }

}

$action-size: 60px;
$action-icon-size: 15px;

@include mq($from: tablet){

  .c-lightbox--inner-close .c-lightbox__action {
    width: $action-size;
    height: $action-size;
  }

  .c-lightbox__action .icon {
    width: $action-icon-size;
    height: $action-icon-size;
  }
}

