/* ==========================================================================
   #DOWNLOAD_BOX
   ========================================================================== */

.c-download-box {
  position: relative;
  display: flex;
  box-shadow: inset 0 0 0 2px $color-secondary;
  border-radius: $global-radius;

  color: $color-secondary;
}

.c-download-box,
.c-download-box__flag {
  transition: $global-transition-fast;
}


.c-download-box__flag {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 12px 13px;

  border-top-left-radius: $global-radius;
  border-bottom-left-radius: $global-radius;

  color: #fff;
  background-color: $color-secondary;
}

.c-download-box__extension {
  display: block;
  margin-bottom: 15px;

  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
}

.c-download-box__icon {
  width: 18px;
  height: 18px;
}

.c-download-box__body {
  display: flex;
  align-items: center;

  padding: 5px 20px;

  font-weight: 400;
}

.c-download-box__title {
  font-size: 16px;
  line-height: 22px;
}


@include mq($from: tablet){

  .c-download-box__flag {
    padding: 16px 15px;
  }

  .c-download-box__extension {
    margin-bottom: 9px;
    font-size: 16px;
  }

  .c-download-box__icon {
    width: 24px;
    height: 24px;
  }

  .c-download-box__body {
    padding: 5px 35px;
  }

  .c-download-box__title {
    font-size: 18px;
    line-height: 28px;
  }

}

@include mq($from: desktop){

  .c-download-box:hover {
    color: $color-primary;
    box-shadow: inset 0 0 0 2px $color-primary;
  }

  .c-download-box:hover .c-download-box__flag {
    background-color: $color-primary;
  }

}
