/* ==========================================================================
   #TOUCH_OVERFLOW */

.c-touch-overflow {
  position: relative;
  top: -30px;

  padding-top: 30px;
  padding-bottom: 50px;

  margin-bottom: -30px;
}

@include mq($until: desktop) {
  .c-touch-overflow {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .c-touch-overflow__inner {
    display: flex;
  }

  .c-touch-overflow .o-layout {
    white-space: nowrap;
  }

  .c-touch-overflow .o-layout__item {
    width: 300px;
    white-space: normal;
  }
}

@include mq($from: tablet){
  .c-touch-overflow {
    padding-bottom: 70px;
    margin-bottom: -20px;
  }
}

@include mq($from: desktop){
  .o-content .c-touch-overflow {
    margin-bottom: -100px;
  }
}
