/* ==========================================================================
   #WORLD_MAP
   ========================================================================== */

.c-world-map {
  position: relative;
}

.c-world-map:before {
  content: "";
  display: block;
  padding-bottom: percentage(644/1280); // 1280 / 644
}

.c-world-map path {
  position: relative;
  transition: $global-transition;
}

.c-world-map .is-painted {
  fill: #262097;
  stroke: #070763;
}

.c-world-map .is-painted:hover {
  z-index: 2;
  fill: #1ae5be;
}

.c-world-map__tooltip {
  position: absolute;
  z-index: 100;
  pointer-events: none;
}

.c-svg-map-tooltip {
  position: relative;
  z-index: 10;

  display: inline-block;
  min-width: 157px;
  padding: 22px 23px 21px 23px;

  color: #666;

  font-size: 16px;
  line-height: 22px;

  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, .4);

  border-radius: $global-radius;

  opacity: 0;
  transition: opacity .3s ease-in-out;

  transform: translate(-27px, 27px);
}

.c-svg-map-tooltip:before {
  position: absolute;
  z-index: -1;
  left: 9px;
  top: 9px;

  content: "";
  display: block;
  width: 25px;
  height: 25px;

  transform-origin: 0 0;
  transform: rotate(-45deg);

  background-color: #fff;
}

.c-svg-map-tooltip__title {
  margin: 0;
  padding: 0;

  color: $color-primary;
  font-weight: 700;

  font-size: 18px;
  line-height: 1;
}

.c-svg-map-tooltip__content:not(:first-child) {
  margin-top: 12px;
}

.c-world-map.has-tooltip .c-svg-map-tooltip {
  opacity: 1;
}
