///* ========================================================================
//   #CLEARFIX
//   ======================================================================== */

// Mixin to drop micro clearfix into a selector. Further reading:
// http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
//
// .usage {
//   @include inuit-clearfix();
// }

@mixin inuit-clearfix() {

  &:before,
  &:after {
    content: "" !important;
    display: block !important;
    clear: both !important;
  }

}


@mixin inuit-clearfix-v2() {

  &:before, /* :before to uncollapse the top margin */
  &:after{
    display: block !important;
    clear: both !important; /* clear fix */
    content: "\a0 " !important; /* &nbsp; - just a space doesn't uncollapse margins */
    visibility: hidden !important; /* make sure not to show anything */
    height: 0 !important;
  }

}
