/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;

  display: block;
  height: $header-height;
  padding: 10px 15px 10px 30px;

  color: #fff;

  // transition: background .3s ease-in-out;
  transition: $global-transition;

  @include browser-scrollbar-overflow-hidden-fix();
}

.c-header__inner {
  transition: $global-transition;
}

.c-header--background {
  background-color: $color-primary;
}

.c-header__action {
  position: absolute;
  z-index: 10;
  top: (24px - $action-mobile-icon-gutter);

  display: inline-block;
  vertical-align: top;

  height: $action-mobile-size;
  min-width: $action-mobile-size;
  line-height: $action-mobile-size;

  padding: 0 10px;

  white-space: nowrap;
  text-align: center;

  font-size: 10px;
  font-weight: 600;
  letter-spacing: .05em;

  color: #fff;
  background: none;
  border: none;
}

.c-header__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 20px;
  height: 20px;

  fill: currentColor;
}

.c-header__action--hamburger {
  left: (20px - $action-mobile-icon-gutter);
}

.c-header__action--search {
  right: (20px - $action-mobile-icon-gutter - 2);
}

.c-header__action--search * {
  pointer-events: none;
}

.c-header__icon--search {
  padding: 2px; // to reduce search icon to 20px - (2 x 2px) = 16px
}

.c-header__icon--close {
  padding: 2px;
}

$logo-width: 116px;
$logo-height: 28px;

.c-header__logo {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  // transform: translate(-50%, -50%);
  margin-left: ($logo-width / -2);
  margin-top: ($logo-height / -2);

  display: inline-block;
  width: 116px;

  color: #fff;
}

.c-header__logo-svg {
  width: $logo-width;
  height: $logo-height;
}

/* Search
   ========================================================================== */

$search-height-mobile: 102px;
$search-height-desktop: 122px;

.c-header__search {
  position: absolute;
  z-index: 100;
  top: -$search-height-mobile;
  left: 0;
  width: 100%;
  height: $search-height-mobile;

  transition: $global-transition;
}

.c-header__search .c-form--search .c-form__group--text .c-form__field {
  border-radius: 0;
  border-left-width: 0;
  border-right-width: 0;
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .07);
}

.c-header__search.is-active {
  // top: $header-height;
  transform: translateY(($search-height-mobile + $header-height));
}

.is-scrolled .c-header--sticky .c-header__search {
  transform: translateY(0);
}

.is-scrolled.is-scrolled--to-top .c-header--sticky .c-header__search.is-active {
  transform: translateY((100px + $header-height));
}

/* Sticky
   ========================================================================== */

@include mq($from: tablet) {
  .c-header--sticky {
    position: fixed;
  }
}

.is-scrolled .c-header--sticky {
  // position: fixed;

  background-color: $color-primary;
  transform: translateY(-200%);

  // transition: none; // yes :)
}

.is-scrolled--ready .c-header--sticky {
  // transition: $global-transition;
  box-shadow: 0 1px 0 #343435, 0 2px 5px rgba(#000, .2);
}

.is-scrolled.is-scrolled--to-top .c-header--sticky {
  transform: translateY(0);
}

@include mq($until: tablet) {
  .is-scrolled .c-header--sticky {
    position: fixed;
    transition: none;
  }

  .is-scrolled--ready .c-header--sticky {
    transition: $global-transition;
    box-shadow: 0 1px 0 #343435, 0 2px 5px rgba(#000, .2);
  }
}

/* Desktop
   ========================================================================== */

.c-header__action--member,
.c-header__desktop-navigation {
  display: none;
}

@include mq($from: tablet) {

  .c-header {
    height: $header-height-desktop;
    // padding: 40px 50px 40px 30px;
  }
  //
  //.c-header__actions {
  //  top: 50px;
  //  right: 50px;
  //}
  //
  //.c-header__action {
  //  height: 60px;
  //  min-width: 60px;
  //  line-height: 60px;
  //  padding: 0 10px;
  //
  //  font-size: 14px;
  //}

  .c-header {
    background-color: $color-primary;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
  }

  $logo-width: 174px;
  $logo-height: 40px;

  .c-header__logo {
    left: 40px;
    margin-left: 0;
    width: $logo-width;
    margin-top: ($logo-height / -2);
  }

  .c-header__logo-svg {
    width: $logo-width;
    height: $logo-height;
  }

  .c-header__action {
    left: auto;
    right: 0;
    top: 0;

    width: $header-height-desktop;
    height: $header-height-desktop;

    border-left: 1px solid rgba(#fff, .05);
  }

  .c-header__icon {
    width: 22px;
    height: 22px;
    padding: 0;
  }

  .c-header__action--hamburger {
    background-color: $color-primary;
  }

  .c-header__action--search {
    right: $header-height-desktop;
  }

  .c-header__action--member {
    display: inline-block;
    right: ($header-height-desktop * 2);
  }

  .c-header__search {
    top: -$search-height-desktop;
    height: $search-height-desktop;
  }

  .c-header__search.is-active {
    transform: translateY(($search-height-desktop + $header-height-desktop));
  }

  .is-scrolled .c-header--sticky .c-header__search {
    transform: translateY(0);
  }

  .is-scrolled.is-scrolled--to-top .c-header--sticky .c-header__search.is-active {
    transform: translateY(($search-height-desktop + $header-height-desktop - 2));
  }

}

@include mq($from: wide) {
  .c-header__desktop-navigation {
    display: block;
    position: absolute;
    top: 50%;
    left: 213px;
    right: 270px;

    transform: translateY(-50%);
    margin-left: -25px;

    text-align: center;
  }
}

