/* ==========================================================================
   #LIST-PROPERTIES */

.o-list-properties {
  list-style: none;
  margin-left: 0;
}

.o-list-properties__item {
  margin-left: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  @include inuit-clearfix;
}

.o-list-properties__label {
  float: left;
  font-weight: 700;
  font-size: 15px;
}

.o-list-properties__value {
  float: right;

  font-size: 18px;
}
