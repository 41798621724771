/* ==========================================================================
   #TABLE
   ========================================================================== */

.c-table {
  font-size: 14px !important;
  line-height: 20px !important;

  border: 1px solid #ccc;
  border-radius: $global-radius;

  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include browser-scrollbar;
}

.c-table table {
  border-collapse: collapse;
  margin-bottom: 0;
}

.c-table table th,
.c-table table td  {
  padding: 10px;
  text-align: left;
  transition: $global-transition;
}

.c-table table th {
  white-space: nowrap;
}

.c-table thead {
  border-bottom: 1px solid #e3e3e3;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .07);
}

.c-table tbody tr:not(:last-child) th,
.c-table tbody tr:not(:last-child) td {
  border-bottom: 1px solid #ececec;
}

.c-table tbody tr:nth-child(even) th,
.c-table tbody tr:nth-child(even) td {
  background-color: #f6f6f6;
}

.c-table table th {
  color: $color-primary;
}

.c-table__number-column {
  width: 80px;
}


@include mq($until: tablet){
  .c-table--responsive {
    border: none;
    border-radius: 0;
    overflow: visible;
  }

  .c-table--responsive,
  .c-table--responsive table,
  .c-table--responsive tbody,
  .c-table--responsive tr,
  .c-table--responsive th,
  .c-table--responsive td {
    display: block;
  }

  .c-table--responsive thead {
    display: none;
  }

  .c-table--responsive tr {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: $global-radius;
  }

  .c-table--responsive table tbody tr th:first-child,
  .c-table--responsive table tbody tr td:first-child {
    border-top-left-radius: $global-radius;
    border-top-right-radius: $global-radius;
  }

  .c-table--responsive table tbody tr th:last-child,
  .c-table--responsive table tbody tr td:last-child {
    border-bottom: none;
    border-bottom-left-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
  }

  .c-table--responsive tbody tr:nth-child(even) th,
  .c-table--responsive tbody tr:nth-child(even) td {
    background-color: transparent;
  }

  .c-table--responsive tbody tr th:nth-child(odd),
  .c-table--responsive tbody tr td:nth-child(odd) {
    background-color: #f6f6f6;
  }

  .c-table--responsive table tbody tr th,
  .c-table--responsive table tbody tr td {
    position: relative;
    padding-left: 115px;
  }

  .c-table--responsive table tbody tr th,
  .c-table--responsive table tbody tr td {
    position: relative;
    padding-left: 116px;
  }

  // make empty cells have the same height like not empty ones
  .c-table--responsive table tbody tr th:after,
  .c-table--responsive table tbody tr td:after {
    content: "";
    display: table;
    clear: both;
  }

  .c-table--responsive table tbody tr th:before,
  .c-table--responsive table tbody tr td:before {
    content: attr(data-th);
    display: inline-block;
    vertical-align: top;
    width: 88px;

    margin-left: -98px;
    margin-right: 10px;

    float: left;

    color: $color-primary;
    font-weight: 700;
    line-height: 20px;
  }
}


@include mq($from: tablet){
  .c-table {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .c-table table th,
  .c-table table td  {
    padding: 27px 11px 23px 25px;
  }

  .c-table__number-column {
    width: 104px;
  }
}


@include mq($from: desktop){
  .c-table table tbody tr:hover th,
  .c-table table tbody tr:hover td {
    color: #fff;
    background-color: $color-primary;
  }
}
