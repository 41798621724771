///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.



// Standardise some UI treatments.

$global-radius: 10px !default;
$global-transition-fast: all 150ms ease-in-out !default;
$global-transition-fastest: all 25ms ease-in-out !default;
$global-transition: all 300ms ease-in-out !default;
$global-transition-600: all 600ms ease-in-out !default;
$global-transition-links: color 300ms ease-in-out, border-color 300ms ease-in-out, background-color 300ms ease-in-out, box-shadow 300ms ease-in-out, opacity 300ms ease-in-out !default;
$global-box-shadow: 0 $inuit-global-spacing-unit-small $inuit-global-spacing-unit rgba(#5d5c63, .15) !default;

$global-transition-transform: transform 300ms ease-in-out !default;
