/* ==========================================================================
   #PAGE_NAVIGATION */

.c-page-navigation {
  position: relative;
  z-index: 50;
  text-align: left;
  margin-top: 30px;
  margin-bottom: -20px;
}

.c-page-navigation__inner {
  position: relative;
  display: inline-block;
  text-align: left;
}

@mixin page-navigation-dropdown($suffix: null){

  .c-page-navigation#{$suffix} .o-menu {
  }

  .c-page-navigation#{$suffix} .o-menu__item,
  .c-page-navigation#{$suffix} .c-page-navigation__toggle {
    display: inline-block;

    font-size: 12px;
    line-height: 1.5;
  }

  .c-page-navigation#{$suffix} .o-menu__item {
    display: block;
    width: 100%;
    margin-bottom: 1px;
  }

  .c-page-navigation#{$suffix} .c-page-navigation__toggle {
    min-width: 154px;
    text-align: left;
  }

  .c-page-navigation#{$suffix} .o-menu__link,
  .c-page-navigation#{$suffix} .c-page-navigation__toggle {
    display: block;
    padding: 9px 15px;
    color: $color-primary;
  }

  .c-page-navigation#{$suffix} .c-page-navigation__toggle {
    border-radius: 20px;
    border: 1px solid $color-primary;
    background: #fff;
  }

  .c-page-navigation#{$suffix} .o-menu__item .c-page-navigation__inner.is-active > .o-menu__link {
  }




  .c-page-navigation#{$suffix} .c-page-navigation__toggle {
    padding-right: 35px;
  }

  .c-page-navigation#{$suffix} .c-page-navigation__toggle * {
    pointer-events: none;
  }

  .c-page-navigation#{$suffix} .c-page-navigation__menu .o-menu__item {
    border-top: 1px solid #eee;
  }

  .c-page-navigation#{$suffix} .c-page-navigation__caret {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: (10px / -2);

    content: "";
    display: block;
    width: 10px;
    height: 10px;

    transform: rotate(90deg);
  }

  .c-page-navigation#{$suffix} .c-page-navigation__menu {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;

    display: none;
    margin-top: -1px;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid $color-primary;
    background: #fff;
  }

  .c-page-navigation#{$suffix} .c-page-navigation__inner.is-active .c-page-navigation__menu {
    display: block;
  }

  .c-page-navigation#{$suffix} .c-page-navigation__inner.is-active .c-page-navigation__toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .c-page-navigation#{$suffix} .c-page-navigation__menu .o-menu__item.is-current {
    display: none;
  }

}

@mixin page-navigation-list($suffix: null){
  .c-page-navigation#{$suffix} .c-page-navigation__toggle {
    display: none;
  }

  .c-page-navigation#{$suffix} {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .c-page-navigation#{$suffix} .o-menu {
    margin-left: -3px;
    margin-top: -6px;
  }

  .c-page-navigation#{$suffix} .o-menu__list {
    display: flex;
    white-space: nowrap;
  }

  .c-page-navigation#{$suffix} .o-menu__item {
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 6px;

    font-size: 12px;
    line-height: 1.5;
  }

  .c-page-navigation#{$suffix} .o-menu__link {
    display: inline-block;
    padding: 9px 15px;
    border-radius: 20px;
    border: 1px solid #d5d5d5;
    color: #b4b4b4;
  }

  .c-page-navigation#{$suffix} .o-menu__item.is-current > .o-menu__link {
    border-color: $color-primary;
    color: $color-primary;
  }
}

@include mq($until: desktop) {
  @include page-navigation-dropdown(--dropdown\@mobile);
  @include page-navigation-list(--list\@mobile);
}

@include mq($from: desktop) {
  @include page-navigation-dropdown(--dropdown\@desktop);
  @include page-navigation-list(--list\@desktop);

  .c-page-navigation {
    margin-top: 70px;
    margin-bottom: -30px;
  }

  .c-page-navigation--list\@desktop {
    text-align: center;
  }

  .c-page-navigation--list\@desktop .o-menu {
    margin-top: -14px;
    margin-left: 0;
  }

  .c-page-navigation .o-menu__item,
  .c-page-navigation .c-page-navigation__toggle {
    font-size: 15px;
  }

  .c-page-navigation--list\@desktop .o-menu__item {
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 14px;
  }

  .c-page-navigation .o-menu__link,
  .c-page-navigation .c-page-navigation__toggle {
    padding: 12px 30px;
  }

  .c-page-navigation .o-menu__link {
    transition: $global-transition-fast;
  }

  .c-page-navigation--list\@desktop .o-menu__link:hover {
    color: #fff !important;
    background-color: $color-primary;
    border-color: $color-primary;
  }

  .c-page-navigation .c-page-navigation__toggle,
  .c-page-navigation--list\@desktop .o-menu__link {
    border-radius: 25px;
  }

  .c-page-navigation--list\@desktop .c-page-navigation__menu {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .c-page-navigation--list\@desktop .c-page-navigation__inner.is-active .c-page-navigation__toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .c-page-navigation--dropdown\@desktop .c-page-navigation__caret {
    right: 25px;
  }

  .c-page-navigation--dropdown\@desktop .o-menu__link:hover {
    color: $color-secondary;
  }


}
