/* ==========================================================================
   #WIDGET
   ========================================================================== */

.c-widget--sidebar {
  padding: 25px 30px 24px 30px;
  box-shadow: inset 0 0 0 1px #d9d9d9;

  border-radius: $global-radius;
}

.c-widget--sidebar .c-widget__title {
  margin-bottom: (9px + 18px);

  font-size: 22px;
  line-height: 28px;
}

.c-widget--sidebar:not(:first-child) {
  margin-top: 50px;
}

/* Menu
   ========================================================================== */

.c-widget__menu {
  margin-left: -30px;
  margin-right: -30px;
}

.c-widget__menu .o-menu__item:not(:last-child) {
  margin-bottom: 25px;
}

.c-widget__menu .o-menu__link {
  position: relative;

  display: block;
  padding: 0 30px;

  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  color: $color-primary;
}

.c-widget__menu .o-menu__link:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  content: "";
  display: block;
  width: 4px;

  background-color: currentColor;

  opacity: 0;

  transition: $global-transition;
}

.c-widget__menu .o-menu__list--sub {
  padding-top: 25px;
}

.c-widget__menu .o-menu__list--sub .o-menu__link {
  font-weight: 300;
}

// Hover

.c-widget__menu .o-menu__link:hover {
  color: $color-tertiary;
}

// Current

.c-widget__menu .o-menu__item.is-current > .o-menu__link {
  color: $color-tertiary;
}

.c-widget__menu .o-menu__item.is-current > .o-menu__link:before {
  opacity: 1;
}

/* Responsive
   ========================================================================== */

@include mq($from: desktop){
  .c-widget--sidebar {
    padding: 29px 30px 25px 30px;
  }
}
