/* ==========================================================================
   #HERO
   ========================================================================== */

.c-hero {
  position: relative;
  overflow: hidden;
}

.c-hero__splash {
  position: relative;
  z-index: 10;
  height: 100vh;
  min-height: 450px;

  color: #fff;
  background: linear-gradient(91.75deg, #18BFFE -42.78%, #191B70 57.8%, #04063B 146.54%);

  // overflow: hidden;
}

.c-hero__splash:before {
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;

  content: "";
  display: block;
  width: 100%;
  height: 31.143%;

  background: linear-gradient(180deg, rgba(75, 72, 190, 0) 0%, #36318b 100%);
}

.c-hero__heading {
  position: absolute;
  z-index: 4;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  width: 100%;
  padding: 50px;
  margin-top: -33px;
  margin-bottom: 0;

  font-size: 40px;
  line-height: 50px;
  letter-spacing: .05em;

  text-align: center;

  color: inherit;
}

.c-hero__decoration {
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: -38px;
}

.c-hero__figure {
  position: relative;
  z-index: 2;

  width: 100%;
  height: 100%;
}

.c-hero__figure--transparent.is-loaded {
  opacity: .15;
}

.c-hero__image {
  max-width: none;
  width: auto;
  height: 100%;
}

/* Buttons
   ========================================================================== */

.c-hero__buttons {
  position: absolute;
  z-index: 5;
  left: 20px;
  bottom: 25px;
  right: 20px;
}

.c-hero__button:not(:last-child) {
  margin-bottom: 10px;
}

/* Separator
   ========================================================================== */

.c-hero__separator {
  margin-top: 7px;
  margin-bottom: 21px;
}

/* Counter
   ========================================================================== */

.c-hero__counter {
  display: none;
}

/* Responsive
   ========================================================================== */

@include mq($from: $inuit-mobile-spacing-breakpoint) {

  .c-hero__buttons {
    left: 40px;
    right: 40px;
  }

  .c-hero__buttons .o-layout--small {
    margin-left: -30px;
  }

  .c-hero__buttons .o-layout--small > .o-layout__item {
    padding-left: 30px;
  }

}

@include mq($from: tablet) {

  .c-hero__splash {
    height: calc(100vh - #{(165px + 90)});
  }

  .c-hero__buttons {
    bottom: 0;
    transform: translateY(50%);
  }

  .c-hero__button:not(:last-child) {
    margin-bottom: 0;
  }

  /* Counter
     ========================================================================== */

  .c-hero__counter {
    display: block;

    position: absolute;
    z-index: 5;
    left: 50%;
    bottom: 90px;

    transform: translateX(-50%);
  }

}

@include mq($from: desktop) {

  .c-hero__heading {
    font-size: 80px;
    line-height: 1;
  }

  .c-hero__counter {
    bottom: 95px;
  }

}

@include mq($from: wide) {

  .c-hero__counter {
    bottom: 120px;
  }

  .c-hero__buttons {
    width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }

}
