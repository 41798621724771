/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  padding-top: 25px;

  color: #fff;
  background-color: $color-primary;
}

/* social
   ========================================================================== */

.c-footer__social {
  margin-bottom: 35px;
}

.c-footer__social .c-social-menu .o-menu__list {
  display: flex;
  justify-content: space-around;

  // margin-left: -$inuit-mobile-spacing-unit;
  // margin-right: -$inuit-mobile-spacing-unit;

  margin-left: 0;
  margin-right: 0;
}

.c-footer__social .c-social-menu .o-menu__link:before {
  background-color: #5550ac;
  border-radius: 10px;
}

.c-footer__social .c-social-menu .o-menu__link:hover {
  color: $color-primary;
}

.c-footer__social .c-social-menu .o-menu__link:hover:before {
  background-color: #fff;
}

@each $platform, $color in $color-social {
  .c-footer__social .c-social-menu .o-menu__link--#{$platform}:hover {
    color: $color;
  }
}

@include mq($until: tablet){
  .c-footer__social .c-social-menu .o-menu__link:before {
    width: 44px;
    height: 44px;
    margin-left: (44px / -2);
    margin-top: (44px / -2);
  }
}

/* whatsapp
   ========================================================================== */

.c-footer__whatsapp {
  margin-bottom: 30px;
  text-align: center;
}

/* separator
   ========================================================================== */

.c-footer__separator {
  position: relative;
  margin-bottom: 10px;
}

/* widgets
   ========================================================================== */

.c-footer .c-widget {
  margin-bottom: 36px;
  font-size: 18px;
}

.c-footer .c-widget__title {
  margin-bottom: 13px;
  font-weight: bold;
  color: $color-secondary;
}

/* menus
   ========================================================================== */

.c-footer__menu .o-menu__item {
  margin-bottom: 9px;
  font-size: 14px;
  letter-spacing: .1em;
}

/* socket
   ========================================================================== */

.c-footer__socket {
  padding-top: 33px;
  padding-bottom: 34px;

  border-top: 1px solid rgba(#fff, .15);

  font-weight: 300;
  font-size: 12px;
  line-height: 22px;

  color: #d3d0ff;
}

.c-footer__copyright {
  margin-bottom: 20px;
}

.c-footer__copyright p {
  margin-bottom: 5px;
}

@include mq($from: desktop) {

  .c-footer__socket {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .c-footer__copyright {
    padding-top: 7px;
  }

  .c-footer__copyright p {
    margin-bottom: 0;
  }

}

/* logos
   ========================================================================== */

.c-footer__logos {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* Responsive
   ========================================================================== */

@include mq($from: 480px) {
  .c-footer__socket {
    text-align: center;
  }

  .c-footer__logos {
    justify-content: center;
  }
}

@include mq($from: tablet) {

  .c-footer {
    padding-top: 52px;
  }

  .c-footer__top {
    position: relative;
  }

  .c-footer__social {
    margin-top: -12px;
    float: left;
  }

  .c-footer__social .o-menu__item {
    margin-right: 25px;
  }

  .c-footer__whatsapp {
    float: right;
  }

  .c-footer__widgets .c-footer__column {
    width: 50%;
  }

  .c-footer__widgets .c-footer__column:first-child {
    float: left;
  }

}

@include mq($from: desktop) {

  .c-footer__widgets .c-footer__column {
    width: 33.33333333%;
  }

  .c-footer__socket {
    text-align: left;
  }

  .c-footer__copyright {
    margin-bottom: 0;
    float: left;
  }

  .c-footer__logos {
    width: auto;
    justify-content: flex-end;
  }

  .c-footer__menu .o-menu__link:hover {
    color: $color-secondary;
  }
}

@include mq($from: wide) {
  .c-footer__socket {
    font-size: 15px;
    line-height: 28px;
  }

  .c-footer__logo {
    margin-left: 14px;
  }

  .c-footer__logo-image {
    width: 77px;
    height: auto;
  }

  .c-footer__widgets .o-layout {
    margin-left: -70px;
  }

  .c-footer__widgets .c-footer__column {
    padding-left: 70px;
    width: 25%;
  }

  .c-footer__widgets .c-footer__column:first-child {
    float: none;
  }

}

/**
 * v2
 */

.c-footer__logos--v2 {
  width: auto;
  margin-left: -5px;
}

.c-footer__logos--v2 .c-footer__logo {
  margin-left: 5px;
}

@include mq($from: wide) {
  .c-footer__logos--v2 {
    margin-left: 0;
  }

  .c-footer__logos--v2 .c-footer__logo {
    margin-left: 10px;
  }

  .c-footer__logos--v2 .c-footer__logo-image {
    width: 70px;
  }
}
