/* ==========================================================================
   #LIST-BORDER
   ========================================================================== */

.o-list-border {
  list-style: none;
  margin-left: 0;
}

.o-list-border__item {
  margin-left: 0;
  padding-top: 6px;
  padding-bottom: 6px;
}

.o-list-border__item:not(:first-child) {
  border-top: 1px solid $color-border;
}


@include mq($from: desktop){
  .o-list-border__item {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}
