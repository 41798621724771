/* ==========================================================================
   #NUMBERS
   ========================================================================== */

.c-numbers {
  position: relative;
  z-index: 10;
  margin-top: (15px + 7 );
  margin-bottom: 30px;
}

.c-numbers .o-layout {
  margin-left: -12px;
}

.c-numbers .o-layout__item {
  padding-left: 12px;
  padding-bottom: 12px;
}

.c-numbers:before,
.c-numbers:after {
  position: absolute;
  z-index: -1;
  left: 50%;

  content: "";
  display: block;

  width: 246px;
  height: 93px;

  margin-left: (246px / -2);

  background: url(../img/number-decoration-top.svg);
}

.c-numbers:before {
  bottom: 100%;
  margin-bottom: 4px;
}

.c-numbers:after {
  top: 100%;
  width: 234px;
  height: 56px;
  margin-top: -3px;
  background: url(../img/number-decoration-bottom.svg);
}

/* Responsive
   ========================================================================== */

@include mq($from: tablet){

  .c-numbers:before {
    left: 34%;
    margin-bottom: 10px;
  }

  .c-numbers:after {
    left: auto;
    right: 38px;
  }

  .c-numbers .o-layout {
    margin-left: -20px;
    margin-bottom: -20px;
  }

  .c-numbers .o-layout__item {
    padding-left: 20px;
    padding-bottom: 20px;
  }

}

@include mq($from: desktop){

  .c-number__number,
  .c-number__icon {
    transition: $global-transition;
  }

  .c-number__number {
    transform-origin: 50% 100%;
  }

  .c-number:hover .c-number__icon {
    transform: scale(1.2);
  }

  .c-number:hover .c-number__number {
    color: #1ae5be;
    transform: scale(1.2);
  }

}
