/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

// We have all of our heading font sizes defined here. Passing these pixel
// values into our `inuit-font-size()` mixin will generate a rem-based
// `font-size` with a pixel fallback, as well as generating a `line-height` that
// will sit on our baseline grid.

$inuit-font-size-h1:  30px !default;
$inuit-font-size-h2:  20px !default;
$inuit-font-size-h3:  18px !default;
$inuit-font-size-h4:  17px !default;
$inuit-font-size-h5:  16px !default;
$inuit-font-size-h6:  15px !default;

h1 {
  @include inuit-font-size($inuit-font-size-h1, 40px);
}

h2 {
  @include inuit-font-size($inuit-font-size-h2);
}

h3 {
  @include inuit-font-size($inuit-font-size-h3);
}

h4 {
  @include inuit-font-size($inuit-font-size-h4);
}

h5 {
  @include inuit-font-size($inuit-font-size-h5);
}

h6 {
  @include inuit-font-size($inuit-font-size-h6);
}

@include mq($from: tablet){

  $inuit-font-size-h1-desktop:  40px !default;
  $inuit-font-size-h2-desktop:  22px !default;
  $inuit-font-size-h3-desktop:  20px !default;
  $inuit-font-size-h4-desktop:  19px !default;
  $inuit-font-size-h5-desktop:  18px !default;
  $inuit-font-size-h6-desktop:  17px !default;

  h1 {
    @include inuit-font-size($inuit-font-size-h1-desktop, 50px);
  }

  h2 {
    @include inuit-font-size($inuit-font-size-h2-desktop);
  }

  h3 {
    @include inuit-font-size($inuit-font-size-h3-desktop);
  }

  h4 {
    @include inuit-font-size($inuit-font-size-h4-desktop);
  }

  h5 {
    @include inuit-font-size($inuit-font-size-h5-desktop);
  }

  h6 {
    @include inuit-font-size($inuit-font-size-h6-desktop);
  }

}
