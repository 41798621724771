/* ==========================================================================
   #MENU
   ========================================================================== */

.o-menu {
  font-size: 0;
}

.o-menu__list {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}

.o-menu__item {
  font-size: $inuit-global-font-size;
}

.o-menu__link {
  vertical-align: top;
}

.o-menu__item--has-caret > .o-menu__link {
  position: relative;
  padding-right: 48px;
}

.o-menu__caret {
  position: absolute;
  right: 28px;
  top: 50%;
  margin-top: (8px / -2);

  content: "";
  display: block;
  width: 8px;
  height: 8px;

  fill: currentColor;
}

/* Nested
   ========================================================================== */

.o-menu--nested .o-menu__item {
  position: static;
}

.o-menu--nested .o-menu__list--sub {
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  z-index: 1;
  left: 100%;
  top: 0;
  width: 100%;
  margin: 0;
}

.o-menu--nested .is-active > .o-menu__list--sub
{
  display: block;
}

.o-menu--nested .is-active--current > .o-menu__list--sub {
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.o-menu--nested .o-menu__item--has-sub {
  @include inuit-clearfix-v2;
}


/* Sub Menu
   ========================================================================== */

//.o-menu--dropdown .o-menu__item--level-0.o-menu__item--has-sub > .o-menu__link:after {
//  position: absolute;
//  bottom: 0;
//  left: 50%;
//  margin-left: -10px;
//
//  content: "";
//  display: inline-block;
//  border: 10px solid transparent;
//  border-bottom-color: $color-primary;
//  border-top-width: 0;
//
//  opacity: 0;
//
//  transition: opacity 0.15s ease-in-out 0.15s;
//}
//
//.o-menu--dropdown .o-menu__item--level-0.o-menu__item--has-sub:hover > .o-menu__link:after {
//  opacity: 1;
//}

.o-menu--dropdown .o-menu__item--has-sub {
  position: relative;
}

.o-menu--dropdown .o-menu__list--sub {
  position: absolute;
  top: -2000px;
  left: 0;
  // margin-top: -24px;

  min-width: 255px;
  padding: 0;

  opacity: 0;
  visibility: hidden;

  transition: opacity 0.15s ease-in-out 0.15s, visibility 0s ease 0.3s, top 0s ease 0.3s;

  line-height: 1;
}

.o-menu--dropdown .o-menu__list--sub .o-menu__list--sub {
  // margin-top: 0;
  left: 100%;
}

.o-menu--dropdown .o-menu__item:hover > .o-menu__list--sub {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transition: opacity 0.15s ease-in-out 0.15s, visibility 0s ease 0.15s, top 0s ease 0.15s;
}

.o-menu--dropdown .o-menu__item--level-0 > .o-menu__link > .o-menu__text {
  position: relative;
  // display: block;
}

.o-menu--dropdown .o-menu__list--sub .o-menu__item:hover > .o-menu__list--sub {
  top: -1px;
}

/* Hover
   ========================================================================== */

.is-window-loaded .o-menu--hover .o-menu__link {
   transition: $global-transition-links;
}

.o-menu--hover .o-menu__item:hover > .o-menu__link:not(.o-menu__link--no-link) {
  color: $color-secondary;
}

.o-menu--hover .o-menu__list--sub .o-menu__item:hover > .o-menu__link:not(.o-menu__link--no-link) {
  color: #fff;
  background-color: $color-primary-hover;
}

/* Tags
   ========================================================================== */

.o-menu--tags {
  margin-left: -3px;
  margin-top: -6px;
}

.o-menu--tags .o-menu__item {
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 6px;

  font-size: 12px;
  line-height: 1.5;
}

.o-menu--tags .o-menu__link {
  display: inline-block;
  padding: 9px 15px;
  border-radius: 100px;
  border: 1px solid #d5d5d5;
  color: #b4b4b4;
}

.o-menu--tags .o-menu__item.is-active > .o-menu__link {
  border-color: $color-primary;
  color: $color-primary;
}

@include mq($from: tablet){
  .o-menu--tags {
    margin-top: -14px;
  }

  .o-menu--tags .o-menu__item {
    font-size: 15px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 14px;
  }

  .o-menu--tags .o-menu__link {
    padding: 12px 30px;
  }
}

/* Pagination
   ========================================================================== */

$size: 40px;
$arrow-size: 10px;
$gutter: 8px;

.o-menu--pagination {
  margin-left: -($gutter / 2);
  margin-top: -$gutter;
}

.o-menu--pagination .o-menu__item {
  display: inline-block;
  vertical-align: top;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  padding-top: $gutter;
  font-size: 12px;
  font-weight: 700;
}

.o-menu--pagination .o-menu__link {
  position: relative;

  display: inline-block;
  width: $size;
  height: $size;
  line-height: $size;

  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba($color-primary, .3);

  text-align: center;

  color: $color-primary;
}

.o-menu--pagination .o-menu__caret {
  position: static;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: (($size - $arrow-size) / 2);
}

.o-menu--pagination .o-menu__item--previous .o-menu__caret {
  transform: rotate(180deg);
}

.o-menu--pagination .o-menu__item.is-active .o-menu__link {
  color: #fff;
  background-color: $color-primary;
}

@include mq($from: tablet){
  $size: 50px;
  $arrow-size: 10px;
  $gutter: 10px;

  .o-menu--pagination {
    margin-left: -($gutter / 2);
    margin-top: -$gutter;
  }

  .o-menu--pagination .o-menu__item {
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
    padding-top: $gutter;
    font-size: 15px;
  }

  .o-menu--pagination .o-menu__link {
    width: $size;
    height: $size;
    line-height: $size;
  }

  .o-menu--pagination .o-menu__caret {
    padding: (($size - $arrow-size) / 2);
  }

  .o-menu--pagination a.o-menu__link:hover {
    box-shadow: none;
    background: $color-primary;
    color: #fff;
  }
}

/* Icons
   ========================================================================== */

.o-menu--icons .o-menu__item:not(:first-child) {
  margin-top: 17px;
}

.o-menu--icons .o-menu__link {
  position: relative;
  display: block;
  padding-left: 36px;
}

.o-menu--icons .o-menu__icon {
  position: absolute;
  left: 0;
  top: 4px;

  display: block;
  width: 16px;
  height: 16px;

  color: $color-secondary;
}

@include mq($from: tablet){
  .o-menu--icons .o-menu__icon {
    top: 8px;
  }
}

@include mq($from: tablet){
  .o-menu--icons .o-menu__link,
  .o-menu--icons .o-menu__icon {
    transition: $global-transition-fast;
  }

  .o-menu--icons .o-menu__link:hover,
  .o-menu--icons .o-menu__link:hover .o-menu__icon {
    color: $color-primary;
  }
}


/* Chars
   ========================================================================== */

.o-menu--chars .o-menu__list {
  margin-left: -6px;
  margin-top: -6px;
}

.o-menu--chars .o-menu__item {
  display: inline-block;
  padding-left: 6px;
  padding-top: 6px;
}

.o-menu--chars .o-menu__link {
  display: inline-block;
  min-width: 28px;
  box-shadow: inset 0 0 0 1px rgba($color-primary, .3);
  border-radius: 25px;
  color: $color-primary;

  font-weight: 700;
  font-size: 15px;
  line-height: 36px;

  text-align: center;
}

.o-menu--chars .o-menu__item.is-current .o-menu__link {
  color: #fff;
  background-color: $color-primary;
}

@include mq($from: desktop){
  .o-menu--chars .o-menu__list {
    margin-left: -5px;
    margin-top: -5px;
  }

  .o-menu--chars .o-menu__item {
    padding-left: 5px;
    padding-top: 5px;
  }

  .o-menu--chars .o-menu__link {
    min-width: 30px;
    line-height: 50px;
  }
}


/* Accordion
   ========================================================================== */

.o-menu--accordion {

}

.o-menu--accordion .o-menu__list--sub {
  position: absolute;
  left: -5000px;
  opacity: 0;

  transition: opacity .3s ease-in-out;
}

.o-menu--accordion .o-menu__item--has-sub > .o-menu__link {
  cursor: pointer;
  user-select: none;
}

.o-menu--accordion .o-menu__item--has-sub > .o-menu__link .o-menu__caret {
  right: 20px;

  width: 10px;
  height: 10px;

  transform: rotate(90deg);
}

// Expanded

.o-menu--accordion .o-menu__item--has-sub.is-expanded > .o-menu__list--sub {
  position: relative;
  left: 0;
  opacity: 1;
}

.o-menu--accordion .o-menu__item--has-sub.is-expanded > .o-menu__link .o-menu__caret {
  transform: rotate(-90deg);
}


