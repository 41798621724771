a {
  color: inherit;
  text-decoration: none;

  // transition: $global-transition;
}

a:focus {
  outline: none;
}
