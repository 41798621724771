/* ==========================================================================
   #ENTRY
   ========================================================================== */

.c-entry {

}

.c-entry__date {
  position: relative;

  margin-bottom: 22px;
  padding-left: 45px;
  display: block;

  color: $color-secondary;
}

.c-entry__day {
  position: absolute;
  left: 0;
  top: -6px;

  font-weight: 700;
  font-size: 36px;
  line-height: 1;
}

.c-entry__month,
.c-entry__year {
  display: block;
  font-size: 13px;
  line-height: 1;

  color: $color-secondary-dark;
}

.c-entry__month {
  margin-bottom: 3px;
}

.c-entry__title {
  margin-bottom: 13px;

  font-size: 20px;
  line-height: 26px;

  color: #666;
}

.c-entry__description {
  margin-bottom: 37px;

  font-size: 16px;
  line-height: 22px;
}

.c-entry__link {
  display: block;

  font-weight: 700;
  color: $color-secondary;
}

.c-entry--in-card .c-entry__description {
  margin-bottom: 0;
}

/* Responsive
   ========================================================================== */

@include mq($from: tablet) {
  .c-entry {
    position: relative;
    padding-left: 80px;
  }

  .c-entry__date {
    position: absolute;
    left: 0;
    top: 3px;

    display: inline-block;
    padding-left: 0;
    padding-top: 37px;
    text-align: center;
  }

  .c-entry__day {
    display: block;
    font-size: 40px;
  }

  .c-entry__month,
  .c-entry__year {
    font-size: 16px;
  }

  .c-entry__title {
    margin-bottom: 9px;
    font-size: 22px;
    line-height: 28px;
  }

  .c-entry__link,
  .c-entry__description {
    font-size: 18px;
    line-height: 28px;
  }

  .c-entry__description {
    margin-bottom: 7px;
  }

  .c-entry--in-card {
    padding-left: 60px;
  }

  .c-entry--in-card .c-entry__description {
    margin-bottom: 0;
  }

  .c-entry--in-card .c-entry__link,
  .c-entry--in-card .c-entry__description {
    font-size: inherit;
    line-height: inherit;
  }
}

@include mq($from: desktop){
  .c-entry__title-link {
    transition: $global-transition-fast;
  }

  .c-entry__title-link:hover {
    color: $color-secondary;
  }
}
