/* ==========================================================================
   #EDITOR_CONTENT
   ========================================================================== */

.s-editor-content {

  h1, h2, h3, h4, h5, h6 {
    &:not(:first-child) {
      margin-top: 36px;
    }
  }

  ul {
    list-style: none; /* Remove default bullets */
    margin-left: 0;
    padding-left: 0;
  }

  ul li {
    position: relative;
    margin-bottom: 20px;
    padding-left: 46px;
  }

  ul li:before {
    position: absolute;
    top: 6px;
    left: 19px;

    content: "";
    display: block;
    width: 8px;
    height: 8px;

    border-radius: 50%;

    background-color: $color-primary;
  }


  ol {
    counter-reset: ordered-list;

    list-style: none; /* Remove default bullets */
    margin-left: 0;
    padding-left: 0;
  }

  ol li {
    counter-increment: ordered-list;

    position: relative;
    margin-bottom: 20px;
    padding-left: 46px;
  }

  ol li:before {
    position: absolute;
    top: 0;
    left: 19px;

    content: counter(ordered-list);
    display: block;

    font-weight: 700;

    color: $color-primary;
  }
}

@include mq($from: tablet){

  .s-editor-content ul li:before {
    top: 10px;
  }

}
