/* ==========================================================================
   #ANIMATED_NUMBERS
   ========================================================================== */

.c-animated-numbers {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;

  border-radius: 200px;
  border: 1px solid rgba(#fff, .1);

  color: #fff;

  line-height: 1;

  text-align: center;
}

.c-animated-numbers__item {
  position: relative;
  display: inline-block;
  padding: 9px 20px 12px 20px;
}

.c-animated-numbers__item:not(:first-child):before {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: (40px / -2);

  content: "";
  display: block;
  width: 1px;
  height: 40px;

  background-color: rgba(#1ae5be, .2);
}

.c-animated-numbers__number {
  display: inline-block;
  margin-bottom: 3px;
  width: 70px;

  font-family: 'Concert One', cursive;
  font-weight: 400;
  font-size: 0;

  transition: color .3s ease-in-out;
}

.c-animated-numbers__count {
  font-size: 40px;
}

.c-animated-numbers__unit {
  font-size: 30px;
}

.c-animated-numbers__suffix {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: .1em;
}

.c-animated-numbers__number.is-completed {
  color: #1ae5be;
}
