/* ==========================================================================
   #FORMS
   ========================================================================== */

.c-form {
  margin-bottom: 32px;
}

.c-form__group:not(:last-child) {
  // margin-bottom: 15px;
  margin-bottom: 18px;
}

.c-form__field {
  position: relative;

  height: $input-height;

  border: 1px solid $input-border-color;
  border-radius: $global-radius;
  // background-color: #f9f9f9;
  background-color: #fff;
}

.c-form__field:not(.c-form__field--recaptcha):not(.c-form__field--submit) {
  box-shadow: 0 2px 3px rgba(0, 0, 0, .07);
}

.c-form__field--submit {
  height: 60px;
  border-bottom-width: 0;
}

//.c-form__field--submit .c-btn {
//  height: 60px;
//}

// .c-form__group.is-filled > .c-form__field,
.c-form__group.is-focused > .c-form__field,
.c-form__group.is-opened > .c-form__field {
  border-color: $color-primary;
}

.c-form__group.is-opened > .c-form__field--select {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: transparent;
}

.c-form__label {
  color: $color-primary;

  white-space: nowrap;
  vertical-align: top;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  background: transparent;
  display: inline-block;
  width: auto;
  line-height: 1;
  height: auto;

  transition: $global-transition;

  font-weight: 400;
}

.c-form__recaptcha {
  text-align: center;
}

.c-form__recaptcha-inner {
  display: inline-block;
  vertical-align: top;
}

.c-form__input,
.c-form__select-rendered {
  position: relative;
  z-index: 1;

  display: block;
  width: 100%;
  height: ($input-height - (2 * $input-border-size));
  padding: 10px;

  border: none;
  background: none;

  font-size: 16px;
  line-height: 22px;

  border-radius: $global-radius;
}

.c-form__input:focus {
  outline: none;
}

.c-form__select-rendered * {
  pointer-events: none;
}

.c-form__field--textarea,
.c-form__input--textarea {
  height: 90px;
}

.c-form__description {
  padding-top: 5px;

  font-size: 12px;
  line-height: 20px;
  color: #999;
}

/* Enhanced Select
   ========================================================================== */

.c-form__select-rendered {
  position: absolute;
  z-index: 40;
  left: 0;
  top: -2px;

  cursor: pointer;

  transition: $global-transition;
}

.c-form__select-rendered:focus {
  outline: 0;
}

//.c-form__group.is-filled > .c-form__field > .c-form__label {
//  color: $color-primary;
//}

.c-form__group--select > .c-form__field:after {
  position: absolute;
  right: 6px;
  top: 50%;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-left: 2px solid $color-primary;
  border-bottom: 2px solid $color-primary;
  transform: rotate(-45deg) translate(-50%, -50%);
  box-sizing: border-box;
  margin-top: -4px;
}

// .c-form__group--select.is-filled > .c-form__field:after,
.c-form__group--select.is-opened > .c-form__field:after {
  border-top-color: $color-primary;
}

.c-form__select-options {
  position: absolute;
  z-index: 500;
  left: -1px;
  top: 100%;
  margin-top: 1px; // form field border-bottom-width

  min-width: calc(100% + 2px);

  border: 1px solid $color-primary;
  //border-bottom-left-radius: $global-radius;
  //border-bottom-right-radius: $global-radius;
  background-color: #fff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, .05);

  display: none;

  overflow: auto;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;

  @include browser-scrollbar();
}

.c-form__group.is-opened .c-form__select-options {
  display: block;
}

.c-form__select-options:focus {
  outline: none;
}

.c-form__select-list {
  display: block;

  list-style: none;
  margin: 0;
  padding: 0;
}

.c-form__select-item {
  padding: 10px 15px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;

  cursor: pointer;
}

.c-form__select-item.is-selected {
  color: $color-primary;
  background-color: #f2f2f2;
}

.c-form__select-item:hover,
.c-form__select-item.is-selected:hover {
  color: #fff;
  background-color: $color-primary;
}

.c-form__select-rendered ~ .c-form__input--select {
  opacity: 0;
}

//.c-form__field--phone .c-form__group .c-form__label {
//  display: none;
//}

.c-form__field--phone .c-form__group--select {
  position: absolute;
  z-index: 300;
  left: 0;
  top: 0;

  width: 90px;
}

.c-form__field--phone .c-form__group--text {
  margin-left: 90px;
}

.c-form__field--phone .c-form__group--select > .c-form__field:after {
  right: (17px + 30px);
}

.c-form__field--phone .c-form__group--select .c-form__option-id {
  position: relative;
  top: -1px;
  padding-left: 24px;
}

.c-form__field--submit,
.c-form__field--recaptcha {
  height: auto;
  border: none;
  background: none;
}

.c-form__field--submit {
  text-align: center;
}

/* Labels as placeholder
   ========================================================================== */

.c-form__group--label-as-placeholder > .c-form__field > .c-form__label {
  position: absolute;
  z-index: 30;
  left: 7px;
  top: 5px;

  padding: 3px;

  border: 1px solid transparent;
  border-radius: 2px;

  color: $input-placeholder-color;

  pointer-events: none;
}

// Focus & Filled

.c-form__group--label-as-placeholder.is-focused:not(.c-form__group--select) .c-form__label,
.c-form__group--label-as-placeholder.is-filled .c-form__label {
  font-size: 75%;
  background-color: #fff;
  border-color: #d9d9d9;
  top: -9px;
}

.c-form__group--label-as-placeholder > .c-form__field > .c-form__input,
.c-form__group--label-as-placeholder > .c-form__field > .c-form__select-rendered {
  padding-top: 10px;
  padding-bottom: 5px;
}

.c-form__group--label-as-placeholder > .c-form__field > .c-form__select-rendered {
  top: 0;
}

.c-form__group--label-as-placeholder > .c-form__field > .c-form__select-rendered {
  opacity: 0;
}

.c-form__group--label-as-placeholder.is-filled > .c-form__field > .c-form__select-rendered {
  opacity: 1;
}

/* Search
   ========================================================================== */

.c-form--search {
  position: relative;
}

.c-form--search .c-form__group {
  margin-bottom: 0;
}

.c-form--search .c-form__group--text .c-form__field,
.c-form--search .c-form__group--text .c-form__input {
  height: 70px;
}

.c-form--search .c-form__group--text .c-form__input {
  padding: 17px 50px;
}

.c-form--search .c-form__group--text .c-form__label {
  left: 47px;
  top: 22px;
}

.c-form--search .c-form__search-icon {
  position: absolute;
  left: 18px;
  top: 50%;
  margin-top: (20px / -2);

  width: 20px;
  height: 20px;
  padding: 2px;

  fill: $color-primary;
}

.c-form--search .c-form__group--submit {
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 50%;
  margin-top: (40px / -2);
}

.c-form--search .c-form__group--submit .c-form__btn {
  min-width: 80px;
  height: 40px;
}

.c-form--search .c-form__group--text.is-focused:not(.c-form__group--select) > .c-form__field > .c-form__label,
.c-form--search .c-form__group--text.is-filled > .c-form__field > .c-form__label {
  color: #fff;
  border-color: transparent;
  background-color: $color-secondary;
  box-shadow: 0 0 0 1px rgba(#fff, .1);
}

/* Validator
   ========================================================================== */

.c-form__group.has-danger > .c-form__field {
  border-color: #f00;
}

.c-form .pristine-error {
  font-size: 13px;
}

/* Responsive
   ========================================================================== */

@include mq($from: tablet) {

  .c-form-wrapper {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .c-form-wrapper--narrow {
    max-width: 520px;
  }

  .c-form__group:not(:last-child) {
    margin-bottom: 29px;
  }

  .c-form__label {
    left: 20px;
    top: 16px;
  }

  .c-form__input,
  .c-form__select-rendered {
    height: ($input-height-desktop - (2 * $input-border-size));
    padding: 20px;
  }

  .c-form__field {
    height: $input-height-desktop;
  }

  .c-form__field--textarea,
  .c-form__input--textarea {
    height: 140px;
  }

  .c-form__field--submit,
  .c-form__field--recaptcha {
    height: auto;
    border: none;
    background: none;
  }

  .c-form__description {
    font-size: 15px;
    line-height: 25px;
  }

  .c-form__group--select > .c-form__field:after {
    right: 16px;
    // margin-top: -3px;

    //border-width: 5px;
    //border-bottom-width: 0;
  }

  .c-form__group--label-as-placeholder > .c-form__field > .c-form__input,
  .c-form__group--label-as-placeholder > .c-form__field > .c-form__select-rendered {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  /* Search
     ========================================================================== */

  .c-form--search {
    max-width: none;
  }

  .c-form--search .c-form__group--text .c-form__field,
  .c-form--search .c-form__group--text .c-form__input {
    height: 90px;
  }

  .c-form--search .c-form__group--text .c-form__input {
    padding: 17px 80px;
  }

  .c-form--search .c-form__group--text .c-form__label {
    left: 80px;
    top: 30px;
  }

  .c-form--search .c-form__search-icon {
    left: 28px;
    margin-top: (22px / -2);

    width: 22px;
    height: 22px;
    padding: 0;
  }

  .c-form--search .c-form__group--submit {
    right: 34px;
    margin-top: (60px / -2);
  }

  .c-form--search .c-form__group--submit .c-form__btn {
    min-width: 120px;
    height: 60px;
    padding-bottom: 15px;

    font-size: 22px;
  }

}
