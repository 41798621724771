/* ==========================================================================
   #NUMBER
   ========================================================================== */

.c-number {
  padding: 30px 20px 24px;

  text-align: center;

  color: #fff;
  background: $color-primary;

  box-shadow: 0 3px 8px rgba(0, 0, 0, .08);
  border-radius: $global-radius;
}

.c-number__number {
  margin-bottom: 1px;
  font-weight: 700;
  font-size: 26px;
}

.c-number__description {
  font-size: 15px;
  line-height: 18px;
}

.c-number__icon {
  display: block;
  width: 50px;
  height: 50px;
  margin-bottom: 26px;
  margin-left: auto;
  margin-right: auto;
}

/* Responsive
   ========================================================================== */

@include mq($from: tablet){

  .c-number {
    padding-top: 39px;
    padding-bottom: 41px;
  }

  .c-number__icon {
    margin-bottom: 25px;
    width: 90px;
    height: 90px;
  }

  .c-number__number {
    margin-bottom: -7px;
    font-size: 38px;
  }

  .c-number__description {
    font-size: 18px;
    line-height: 24px;
  }

}

