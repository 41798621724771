/* ==========================================================================
   #SOCIAL_MENU
   ========================================================================== */

$icon-clickable-area-size: 52px;
$icon-circle-size: 34px;
$icon-size: 22px;

.c-social-menu {
  position: relative;
  z-index: 10;
  font-size: 0;
  text-align: center;
}

.c-social-menu .o-menu__item {
  display: inline-block;
}

.c-social-menu .o-menu__link {
  position: relative;
  display: inline-block;
  width: $icon-clickable-area-size;
  height: $icon-clickable-area-size;

  color: currentColor;
}

.c-social-menu .o-menu__link:hover {
  color: $color-primary;
}

.c-social-menu .o-menu__link:before {
  position: absolute;
  left: 50%;
  top: 50%;

  content: "";
  display: block;
  width: $icon-circle-size;
  height: $icon-circle-size;
  margin-top: ($icon-circle-size / -2);
  margin-left: ($icon-circle-size / -2);

  border-radius: 50%;
  // border: 2px solid;
}

.c-social-menu .o-menu__icon {
  position: absolute;
  left: 50%;
  top: 50%;

  display: block;
  width: $icon-size;
  height: $icon-size;
  margin-top: ($icon-size / -2);
  margin-left: ($icon-size / -2);

  fill: currentColor;
}

@include mq($from: tablet) {

  $icon-clickable-area-size: 52px;
  $icon-circle-size: 52px;
  $icon-size: 26px;

  .c-social-menu {
    position: relative;
    z-index: 10;
    font-size: 0;
    text-align: center;
  }

  .c-social-menu .o-menu__item {
    display: inline-block;
  }

  .c-social-menu .o-menu__link {
    position: relative;
    display: inline-block;
    width: $icon-clickable-area-size;
    height: $icon-clickable-area-size;

    color: currentColor;
  }

  .c-social-menu .o-menu__link,
  .c-social-menu .o-menu__link:before {
    transition: $global-transition;
  }

  .c-social-menu .o-menu__link:hover {
    color: $color-primary;
  }

  .c-social-menu .o-menu__link:before {
    position: absolute;
    left: 50%;
    top: 50%;

    content: "";
    display: block;
    width: $icon-circle-size;
    height: $icon-circle-size;
    margin-top: ($icon-circle-size / -2);
    margin-left: ($icon-circle-size / -2);

    border-radius: $global-radius;
    // border: 2px solid;
  }

  .c-social-menu .o-menu__icon {
    position: absolute;
    left: 50%;
    top: 50%;

    display: block;
    width: $icon-size;
    height: $icon-size;
    margin-top: ($icon-size / -2);
    margin-left: ($icon-size / -2);

    fill: currentColor;
  }

}
