/* ==========================================================================
   #JOIN
   ========================================================================== */

.c-join {
  font-size: 26px;
  line-height: 32px;

  text-align: center;
  color: #fff;
}

.c-join p {
  margin-bottom: 0;
}

.c-join .c-join__subtitle {
  padding-left: 15px;
  padding-right: 15px;
}

.c-join__action {
  margin-top: 82px;
}

/* Responsive
   ========================================================================== */

@include mq($from: tablet){

  .c-join {
    padding-top: 14px;
    padding-bottom: 26px;

    font-size: 36px;
    line-height: 50px;
  }

  .c-join__action {
    margin-top: 62px;
  }

  .c-join__action .c-btn {
    padding: 16px 32px;

    font-size: 22px;
    line-height: 28px;
  }

}
