/* ==========================================================================
   #BG
   ========================================================================== */

.o-bg {
  position: relative;
  z-index: 10;
}

.o-bg__inner {
  position: relative;
  z-index: 10;
}

.o-bg__bg,
.o-bg__overlay,
.o-bg__picture {
  position: absolute;
  z-index: 9;
  left: 0;
  top: 0;

  display: block;
  width: 100%;
  height: 100%;
}

.o-bg__overlay {
  z-index: 9;
}

.o-bg__picture {
  z-index: 8;
}
