/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */

blockquote {
  position: relative;

  padding: 30px;

  border-left: 4px solid $color-primary;

  color: $color-primary;

  background-color: #f7f7ff;
}

blockquote > *:last-child {
  margin-bottom: 0;
}

@include mq($from: tablet){
  blockquote {
    margin-bottom: 50px;
  }

  blockquote:not(:first-child) {
    margin-top: 50px;
  }
}
