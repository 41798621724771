/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 * 4. Smooth scrolling on the whole document
 * 5. Fortunately, users in some browsers/operating systems can express their preference for reduced motion effects with the Reduce Motion API. We can listen for that setting in our CSS, and turn off smooth scrolling for them.
 */

html {
  // font-size: ($inuit-global-font-size / 16px) * 1em; /* [1] */
  // line-height: $inuit-global-line-height / $inuit-global-font-size; /* [1] */

  //font-size: 13px; /* From Design */
  //line-height: 18px;

  font-size: 16px; /* Revision request */
  line-height: 1.375; // 22/16
  font-weight: 300;

  overflow-y: scroll; /* [2] */
  min-height: 100%; /* [3] */
  color: $color-body;

  //scroll-behavior: smooth; /* [4] */
  //
  //@media screen and (prefers-reduced-motion: reduce) {
  //  scroll-behavior: auto; /* [5] */
  //}
}

@include mq($from: tablet) {
  html {
    font-size: 18px;
    line-height: 1.55555555; // 28/18
  }
}

body {
  @include browser-scrollbar();
}

html.no-hidden-scrollbars.has-css-scrollbars.browser-scrollbar-fix.is-off-canvas-active {
  margin-right: 8px;
}

#page {
  transition: $global-transition;
}
