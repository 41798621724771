/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

$inuit-wrapper-width: 1200px !default;

@if (type-of($inuit-wrapper-width) != number) {
  @error "`#{$inuit-wrapper-width}` needs to be a number.";
}

.o-wrapper {
  @include inuit-clearfix();
  padding-right: $inuit-mobile-spacing-unit;
  padding-left: $inuit-mobile-spacing-unit;
  margin-right: auto;
  margin-left: auto;
  max-width: $inuit-wrapper-width;
}

/* Remove max width
   ========================================================================== */

.o-wrapper--fluid,
.o-wrapper--flush {
  max-width: none;
}

/* Responsive Improvements
   ========================================================================== */

@include mq($from: $inuit-mobile-spacing-breakpoint) {

  /**
   * $inuit-mobile-spacing-unit fix
   */

  .o-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }

}
