///* ========================================================================
//   #BROWSER_SCROLLBAR
//   ======================================================================== */

@mixin browser-scrollbar() {

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #5e6b79;
  }

}

@mixin browser-scrollbar-overflow-hidden-fix(){

  html.no-hidden-scrollbars.has-css-scrollbars.browser-scrollbar-fix.is-off-canvas-active & {
    width: calc(100% - 8px);
  }

}
