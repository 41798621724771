/* ==========================================================================
   #FANCY_LINKS
   ========================================================================== */

@mixin fancy-links($suffix: null) {
  &#{$suffix} {
    a {
      position: relative;
      z-index: 2;
      display: inline-block;
      padding: 0 3px;

      color: $color-primary;
    }

    a:before,
    a:after {
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);

      content: "";
      display: block;
      width: 100%;
      height: 1em;

      background-color: #ebfaff;
    }

    a:hover {
      text-decoration: none !important;
    }
  }
}

@mixin fancy-links-hover($suffix: null) {
  &#{$suffix} {
    a,
    a:before,
    a:after {
      transition: $global-transition;
    }

    a:hover {
      color: #fff;
    }

    a:hover:before {
      // opacity: 0;
    }

    a:hover:after {
      top: 0;
      width: calc(100% + 10px);
      height: 100%;
      background-color: $color-primary;
      border-radius: 3px;
    }
  }
}

.s-fancy-links {

  @include fancy-links();

  @include mq($from: desktop) {

    @include fancy-links-hover();

    //@include fancy-links(\@desktop);
    //@include fancy-links-hover(\@desktop);
  }

  @include mq($from: wide) {
    @include fancy-links(\@wide);
    @include fancy-links-hover(\@wide);
  }

}

