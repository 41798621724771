/* ==========================================================================
   #SSS
   ========================================================================== */

.c-sss__group:not(.is-expanded) .o-menu__item:nth-child(3) ~ .o-menu__item {
  display: none;
}

.c-sss__group.is-expanded .c-card--arrow-at-bottom .c-card__arrow-svg {
  transform: rotate(-90deg);
}
