/* ==========================================================================
   #NEWS
   ========================================================================== */

.c-news {
  border-top: 1px solid #ebebeb;
}

.c-news__items {
  margin-bottom: 50px;
}

.c-news__item {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ebebeb;
}

@include mq($from: tablet){

  .c-news__item {
    padding-left: 40px;
    padding-right: 40px;
  }

}
