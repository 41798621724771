/* ==========================================================================
   #SEPARATOR
   ========================================================================== */

.c-separator {
  position: relative;
  overflow: hidden;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(../img/separator-mobile.svg);
}

.c-separator:before {
  content: "";
  display: block;
  padding-bottom: 15%;
}


.c-separator--footer {
  background-image: url(../img/separator-footer-mobile.svg);
}

@include mq($from: tablet){
  .c-separator {
    background-image: url(../img/separator-desktop.svg);
  }

  .c-separator:before {
    padding-bottom: 10%;
  }

  .c-separator--footer {
    background-image: url(../img/separator-footer-desktop.svg);
  }
}
