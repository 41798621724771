///* ========================================================================
//   #MAIN
//   ======================================================================== */

$inuit-fractions: 1 2 3 4 5 6;

$color-primary: #36318b;
$color-primary-hover: lighten($color-primary, 10%);

$color-success: #0c9000;
$color-success-bg: #93ff8a;

$color-danger: #db0000;
$color-danger-bg: #ff9d9d;

$color-secondary: #9ad3e7;
$color-secondary-dark: #4fa4c2;

$color-tertiary: #1ae5be;

$color-heading: $color-primary;
$color-body: #666;

$color-border: #ebebeb;

$color-social: (
  facebook: #00549e,
  twitter: #1fa5f5,
  instagram: #f1055a,
  youtube: #f00,
  linkedin: #0077b7,
);

$input-placeholder-color: #83888a;
$input-border-color: #d9d9d9;
$input-height: 40px;
$input-height-desktop: 60px;
$input-border-size: 1px;

$form-section-background-color: #f0f5f6;

$font-body: 'Roboto Slab', serif;

$header-height-desktop: 90px;

$inuit-ratios: (
  "16\\:9"  : (16:9)
) !default;

$inuit-mobile-spacing-breakpoint: 405px;
$inuit-mobile-spacing-unit: 20px;

$inuit-wrapper-inner-width: 1280px;
$inuit-wrapper-width: ($inuit-wrapper-inner-width + (40px * 2)); // 40px -> $inuit-global-spacing-unit; // but not yet calculated

$inuit-spacing-directions: (
  null: null,
  "-top": "-top",
  // "-right": "-right",
  "-bottom": "-bottom",
  // "-left": "-left",
  // "-horizontal": "-left" "-right",
  // "-vertical": "-top" "-bottom",
) !default;

$inuit-spacing-properties: (
  // "padding": "padding",
  "margin": "margin",
) !default;

$inuit-spacing-sizes: (
  null: $inuit-global-spacing-unit + 5, // 45 at design
  "-tiny": $inuit-global-spacing-unit-tiny,
  "-small": $inuit-global-spacing-unit-small,
  "-large": $inuit-global-spacing-unit-large,
  "-huge": $inuit-global-spacing-unit-huge,
  "-none": 0
) !default;

$inuit-responsive-spacing-directions: $inuit-spacing-directions;
$inuit-responsive-spacing-properties: $inuit-spacing-properties;
$inuit-responsive-spacing-sizes: $inuit-spacing-sizes;

$header-height: 68px;

$action-mobile-size: 44px;
$action-mobile-icon-size: 20px;
$action-mobile-icon-gutter: ($action-mobile-size - $action-mobile-icon-size) / 2;


$icon-scale-ratio: (4);
