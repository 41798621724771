/* ==========================================================================
   #HEADING
   ========================================================================== */
.c-heading {
  font-size: 22px;
  line-height: 32px;
}

@include mq($from: tablet){

  .c-heading {
    margin-bottom: 40px;

    font-size: 30px;
    line-height: 40px;
  }

  .c-heading-large\@tablet {
    font-size: 40px;
    line-height: 60px;
  }

}
