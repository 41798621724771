/* ==========================================================================
   #SIMPLE_IMAGE
   ========================================================================== */

.c-simple-image {
  display: inline-block;
  text-align: center;
}

.c-simple-image__picture {
  box-shadow: 0 15px 60px rgba(0, 0, 0, .1);
}

.c-simple-image__picture {
  margin-bottom: 22px;
}

.c-simple-image__title,
.c-simple-image__subtitle {
  position: relative;
  z-index: 10;
  display: block;
}

.c-simple-image--fixed .c-simple-image__picture {
  width: 170px;
}

/* Responsive
   ========================================================================== */

@include mq($from: tablet){
  .c-simple-image--fixed .c-simple-image__picture {
    width: 267px;
  }
}

@include mq($from: desktop){

  .c-simple-image__subtitle,
  .c-simple-image__picture {
    transition: $global-transition;
  }

  .c-simple-image:hover .c-simple-image__subtitle {
    color: #1ae5be;
    transform: scale(1.2);
  }

  .c-simple-image:hover .c-simple-image__picture {
    box-shadow: 0 0 0 4px #fff, 0 0 0 8px #1ae5be;
  }

}
