/* ==========================================================================
   #MAP_SECTION
   ========================================================================== */

.c-map-section {
  display: none;
}


@include mq($from: desktop){

  .c-map-section {
    position: relative;
    z-index: 10;
    display: block;
    overflow: hidden;
  }

  .c-map-section:before {
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50px;
    margin-left: (830px / -2);

    content: "";
    display: block;
    width: 830px;
    height: 93px;

    background: url(../img/map-decoration.svg);
    background-size: contain;
  }

}

