/* ==========================================================================
   #MIX */

.u-middle {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.u-center {
  text-align: center !important;
}

.is-clickable {
  cursor: pointer;
}

.u-full-height {
  height: 100%;
}

.u-left-space {
  padding-left: 5px;
}

.u-full {
  width: 100%;
}

.u-clip {
  overflow: hidden;
}

.u-nowrap {
  white-space: nowrap;
}

@include mq($from: tablet){
  .u-center\@tablet {
    text-align: center !important;
  }
}

@include mq($from: desktop){
  .u-center\@desktop {
    text-align: center !important;
  }
}
