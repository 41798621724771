/* ==========================================================================
   #FANCY_ACTION
   ========================================================================== */

.c-fancy-action {
  position: relative;

  display: block;
  padding: 24px 72px 19px;

  border-radius: 10px;

  color: $color-body;
  background: #fff;
  box-shadow: 0 2px 0 #1ae5be, 0 10px 50px rgba(0, 0, 0, .2);
}

.c-fancy-action__title {
  display: block;

  font-weight: 700;
  font-size: 16px;
  line-height: 1;

  color: $color-primary;
}

.c-fancy-action__description {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
}

.c-fancy-action__icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);

  display: inline-block;
  width: 42px;
  height: 42px;
}

.c-fancy-action__icon[src*="speaker"] {
  left: (20px - 2px);
  margin-top: -2px;
}

.c-fancy-action__arrow {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: (44px / -2);

  width: 44px;
  height: 44px;
  padding: 14px;

  fill: $color-primary;
  background-color: #f4f4f4;
  border-radius: 50%;
}

@include mq($from: desktop) {

  .c-fancy-action {
    padding: 33px 100px 32px;
    box-shadow: 0 5px 0 #1ae5be, 0 10px 50px rgba(0, 0, 0, .3);
  }

  .c-fancy-action__title {
    font-size: 24px;
    line-height: 1.2;
  }

  .c-fancy-action__description {
    font-size: 16px;
    line-height: 1.5;
  }

  .c-fancy-action__icon {
    width: 60px;
    height: 60px;
  }

  .c-fancy-action__arrow {
    right: 20px;
    margin-top: (60px / -2);

    width: 60px;
    height: 60px;
    padding: 20px;
  }

}

@include mq($from: wide) {

  .c-fancy-action,
  .c-fancy-action__arrow,
  .c-fancy-action__icon {
    transition: $global-transition;
  }

  .c-fancy-action {
    padding: 51px 133px 50px;
    box-shadow: 0 5px 0 #1ae5be, 0 10px 50px rgba(0, 0, 0, .3);
  }

  .c-fancy-action__title {
    font-size: 34px;
    line-height: 1.2;
  }

  .c-fancy-action__description {
    font-size: 18px;
    line-height: 1.5;
  }

  .c-fancy-action__icon {
    left: 32px;

    width: 85px;
    height: 85px;
    transform-origin: 0 50%;
  }

  .c-fancy-action__icon[src*="speaker"] {
    left: (32px + 5);
    margin-top: -5px;
  }

  .c-fancy-action__arrow {
    right: 30px;
    margin-top: (80px / -2);

    width: 80px;
    height: 80px;
    padding: 28px;
  }

  .c-fancy-action:hover {
    // background-color: #d5feff;
  }

  .c-fancy-action:hover .c-fancy-action__arrow {
    background-color: $color-primary;
    fill: #fff;
  }

}

@include mq($from: 1400px) {

  //.c-fancy-action:hover {
  //  padding-left: 153px;
  //}
  //
  //.c-fancy-action:hover .c-fancy-action__icon {
  //  transform: translateY(-50%) scale(1.3);
  //}

}
