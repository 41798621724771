/* ==========================================================================
   #CONTENT
   ========================================================================== */

.o-content {
  padding-top: 50px;
  padding-bottom: 50px;
}

.o-content--small {
  padding-top: 30px;
  padding-bottom: 30px;
}

.o-content--side {
  padding-left: 15px;
  padding-right: 15px;
}

.o-content--flush {
  padding-top: 0;
  padding-bottom: 0;
}

.o-content--flush-bottom {
  padding-bottom: 0;
}

.o-content--flush-top {
  padding-top: 0;
}

@include mq($from: tablet){
  .o-content {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .o-content--side {
    padding-left: 0;
    padding-right: 0;
  }

  .o-content--small {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .o-content--flush {
    padding-top: 0;
    padding-bottom: 0;
  }

  .o-content--flush-bottom {
    padding-bottom: 0;
  }

  .o-content--flush-top {
    padding-top: 0;
  }



  .o-content\@tablet {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .o-content--small\@tablet {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .o-content--flush\@tablet {
    padding-top: 0;
    padding-bottom: 0;
  }

  .o-content--flush-bottom\@tablet {
    padding-bottom: 0;
  }

  .o-content--flush-top\@tablet {
    padding-top: 0;
  }
}

@include mq($from: desktop){
  .o-content\@desktop {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .o-content--small\@desktop {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .o-content--flush\@desktop {
    padding-top: 0;
    padding-bottom: 0;
  }

  .o-content--flush-bottom\@desktop {
    padding-bottom: 0;
  }

  .o-content--flush-top\@desktop {
    padding-top: 0;
  }
}
