/* ==========================================================================
   #FEATURED_MEDIA
   ========================================================================== */

.c-featured-media {
  position: relative;
  display: block;
  margin-bottom: 32px;
}

.c-featured-media .c-responsive-image,
.c-featured-media .c-responsive-image__img {
  width: 100%;
  height: auto;
}

.c-featured-media__icon {
  position: absolute;
  z-index: 20;
  left: 20px;
  bottom: 20px;

  display: block;
  width: 50px;
  height: 50px;

  border-radius: 50%;
  color: #fff;
  background-color: $color-primary;
}

.c-featured-media__icon .icon {
  position: relative;
  width: 100%;
  height: 100%;
  padding: ((50px - 18px) / 2);
}

.c-featured-media__icon .icon-play-sharp {
  left: 2px;
}

@include mq($from: tablet){
  .c-featured-media {
    margin-bottom: 52px;
  }
}

@include mq($from: desktop){
  .c-featured-media__icon,
  .c-featured-media .c-responsive-image {
    transition: $global-transition;
  }

  a.c-featured-media:hover .c-responsive-image {
    box-shadow: 0 0 0 4px #fff, 0 0 0 8px $color-primary;
  }

  a.c-featured-media:hover .c-featured-media__icon {
    transform: scale(1.2);
  }
}
