/* ========================================================================
   #COLOR
   ======================================================================== */

.u-color-gray {
  color: #f4f4f4;
}

.u-color-primary {
  color: $color-primary;
}

.u-bg-gray {
  background-color: #f4f4f4;
}

.u-bg-gray-dark {
  background-color: #ededed;
}

.u-bg-gray-gradient {
  background: radial-gradient(45.97% 110.78% at 50% 50%, #FFFFFF 0%, #D6E1E1 100%);
}
