/* ==========================================================================
   #CARD
   ========================================================================== */

.c-card {
  position: relative;
  display: block;

  box-shadow: 0 15px 60px rgba(0, 0, 0, .1);
  background-color: #fff;

  border-radius: 10px;
  // overflow: hidden;

  transition: $global-transition;
}

.c-card p {
  margin-bottom: 20px;
}

.c-card p:last-child {
  margin-bottom: 0;
}

.c-card p a:hover {
  text-decoration: underline;
}

.c-card,
.c-card__link {
  border-radius: 10px;
}

.c-card__top,
.c-card__figure,
.c-card__picture,
.c-card__image {
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.c-card__top {
  position: relative;

  display: block;
}

.c-card__body {
  display: block;

  padding: 28px;
}

.c-card__link {
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;

  display: block;
  width: 100%;
  height: 100%;
}

.c-card__arrow {
  position: absolute;
  z-index: 10;
  right: 20px;
  bottom: 20px;

  transition: $global-transition;

  border: none;
  background: none;
  margin: 0;
  padding: 0;
}

.c-card__top .c-card__arrow {
  bottom: auto;
  top: 100%;
  margin-top: (44px / -2);

  color: #fff;
}

.c-card__arrow-svg {
  display: inline-block;
  vertical-align: top;

  width: 44px;
  height: 44px;

  padding: 14px;

  border-radius: 50%;

  fill: #fff;
  background-color: $color-primary;
}

.c-card__arrow-svg.icon-arrow {
  padding: 16px;
}

.c-card__arrow--ghost .c-card__arrow-svg {
  background-color: #fff;
  fill: $color-primary;
  box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(54, 49, 139, 0.3);
}

.c-card__title {
  margin-top: -6px;
  margin-bottom: 19px;

  font-weight: 700;
  font-size: 22px;
  // line-height: 28px;
  line-height: 32px;

  color: $color-primary;
}

.c-card--arrow-at-content {
  padding-bottom: (45px + 20px - 17px);
}

.c-card--arrow-at-bottom {
  padding-bottom: 23px;
}

.c-card--arrow-at-bottom .c-card__arrow {
  right: auto;
  bottom: auto;
  left: 50%;
  top: 100%;
  margin-left: (44px /-2);
  margin-top: (44px /-2);
}

.c-card--arrow-at-bottom .c-card__arrow-svg {
  transform: rotate(90deg);
}

/* Primary
   ========================================================================== */

.c-card--primary {
  color: #fff;
  background-color: $color-primary;
}

.c-card--primary .c-card__body {
  background-color: transparent;
}

.c-card--primary .c-card__title {
  color: #fff;
}

.c-card--primary .c-card__arrow-svg {
  background-color: #fff;
  fill: $color-primary;
}

/* Background
   ========================================================================== */

.c-card--background {
  color: #fff;
  padding-bottom: 0;
}

.c-card--background .c-card__body {
  padding-bottom: (45px + 20px - 17px);
}

.c-card--background .c-card__top,
.c-card--background .c-card__figure,
.c-card--background .c-card__picture,
.c-card--background .c-card__picture:before,
.c-card--background .c-card__image {
  border-radius: 10px;
  height: 100%;
}

.c-card--background .c-card__top {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-card--background .c-card__body {
  position: relative;
  z-index: 2;
  height: 100%;
}

.c-card--background .c-card__title {
  color: #fff;
}

.c-card--background .c-card__picture:before,
.c-card--background .c-card__picture:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: "";
  display: block;

  background: linear-gradient(180deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
  opacity: .4;

  transition: $global-transition;
}

.c-card--background .c-card__picture:after {
  background: linear-gradient(180deg, rgba($color-primary, 1) 0%, rgba($color-primary, 0) 100%);
  opacity: 0;
}

a.c-card--background:hover .c-card__picture:before,
.c-card--background .c-card__link:hover ~ .c-card__top .c-card__picture:before {
  opacity: 0;
}

a.c-card--background:hover .c-card__picture:after,
.c-card--background .c-card__link:hover ~ .c-card__top .c-card__picture:after {
  opacity: .8;
}

/* Hover
   ========================================================================== */

.c-card__arrow:hover,
.c-card__link:hover ~ .c-card__arrow,
.c-card__link:hover ~ .c-card__top .c-card__arrow,
.c-card__link:hover ~ .c-card__body .c-card__arrow {
  transform: scale(1.2);
}

/* Responsive
   ========================================================================== */

@include mq($from: tablet) {
  .c-card p {
    margin-bottom: 10px;
  }
}

@include mq($from: wide) {
  .c-card__title {
    font-size: 30px;
    line-height: 40px;
  }

  .c-card__content {
    font-size: 22px;
    line-height: 32px;
  }

  .c-card__body {
    padding: 50px 40px 60px;
  }

  .c-card__arrow {
    right: 30px;
    bottom: 30px;
  }

  .c-card__arrow-svg {
    width: 60px;
    height: 60px;
    padding: 16px;
  }

  .c-card__arrow--ghost .c-card__arrow {
    margin-left: (50px /-2);
    margin-top: (50px /-2);
  }

  .c-card__arrow--ghost .c-card__arrow-svg {
    width: 50px;
    height: 50px;
    padding: 19px;
  }

  .c-card__top .c-card__arrow {
    bottom: auto;
    margin-top: (60px / -2);
  }

}
