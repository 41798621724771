/* ==========================================================================
   #TEXT-WITH-IMAGE
   ========================================================================== */

.c-text-with-image {
  overflow: hidden;
}

.c-text-with-image__cell--info {
  padding: 40px 20px;

  color: #fff;
  background-color: $color-primary;
}

.c-text-with-image__title {
  margin-bottom: 17px;

  font-size: 22px;
  line-height: 30px;
}

.c-text-with-image__description {
  margin-bottom: 25px;

  font-size: 20px;
  line-height: 26px;
}

.c-text-with-image__link {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: $color-secondary;
}

.c-text-with-image__body {
  position: relative;
  z-index: 10;
}

.c-text-with-image__info {
  width: 100%;
}

/* Carousel
   ========================================================================== */

.c-text-with-image__cell--info .c-carousel {
  position: relative;
  z-index: 1;
  margin-left: -20px;
  margin-right: -20px;
}

.c-text-with-image__cell--info .c-carousel__item {
  padding-left: 20px;
  padding-right: 20px;
}

.c-text-with-image__cell--info .c-carousel__content {
  position: relative;
  padding-bottom: 20px;

  min-height: 134px;
}

.c-text-with-image__cell--info .c-carousel .c-text-with-image__link {
  position: absolute;
  left: 0;
  bottom: 0;
}

.c-text-with-image .c-text-with-image__view-all {
  position: absolute;
  z-index: 10;
  right: 0;
  bottom: -9px;
}

.c-text-with-image__cell--info .flickity-page-dots {
  text-align: left;
  padding-left: 5px;
  bottom: 4px;
  display: inline-block;
  width: auto;
  left: auto;
  right: 134px;
}

/* Responsive
   ========================================================================== */

@include mq($from: $inuit-mobile-spacing-breakpoint){
  .c-text-with-image__cell--info {
    padding: 40px;
  }
}

@include mq($from: tablet) {
  .c-text-with-image__title {
    margin-bottom: 30px;
  }

  .c-text-with-image {
    font-size: 0;
  }

  .c-text-with-image__cell {
    display: inline-block;
    align-items: center;
    vertical-align: top;
    font-size: $inuit-global-font-size;
    width: 50%;
    overflow: hidden;
  }

  .c-text-with-image__cell--info {
    padding: 97px 40px 90px;
  }

  .c-text-with-image__cell--figure .c-text-with-image__picture {
    height: 100%;
    overflow: hidden;
  }

  .c-text-with-image__cell--figure .c-text-with-image__image {
    position: absolute;
    z-index: -1;
    pointer-events: none;
    right: 0;
  }

  .c-text-with-image__cell--figure * {
    height: 100% !important;
  }

  .c-text-with-image__title {
    font-size: 30px;
  }

  .c-text-with-image__description {
    font-size: 22px;
    line-height: 28px;
  }

  .c-text-with-image__info {
    position: relative;
  }

  .c-text-with-image__body {
    min-height: (215px + 12px);
  }

  .c-text-with-image__description {
    padding-bottom: 35px;
  }

  .c-text-with-image__link {
    position: absolute;
    left: 0;
    bottom: 0;

    font-size: 18px;
    line-height: 28px;
  }

  .c-text-with-image__cell--info__cell--info .c-carousel__content {
    min-height: (183px + 84);
  }

  /* Carousel
     ========================================================================== */

  .c-text-with-image__cell--info__cell--info .c-carousel {
    margin-left: -40px;
    margin-right: -40px;
  }

  .c-text-with-image__cell--info__cell--info .c-carousel__item {
    padding-left: 40px;
    padding-right: 40px;
  }

}

@include mq($from: tablet, $until: desktop) {
  .c-text-with-image__cell--info .c-carousel__content {
    padding-bottom: 80px;
  }

  .c-text-with-image .c-carousel .c-text-with-image__link {
    bottom: 49px;
  }

  .c-text-with-image__cell--info .flickity-page-dots {
    right: auto;
    left: 27px;
  }
}

@include mq($from: desktop) {
  .c-text-with-image__title {
    margin-bottom: 50px;
  }

  .c-text-with-image--description-large\@desktop .c-text-with-image__description {
    font-size: 40px;
    line-height: 46px;
  }

  /* Carousel
     ========================================================================== */

  .c-text-with-image .c-text-with-image__view-all {
    right: 43px;
  }

  .c-text-with-image__cell--info .flickity-page-dots {
    right: 200px;
  }

}

@include mq($from: wide) {
  .c-text-with-image__info {
    left: 100%;
    width: 600px;
    margin-left: -600px;
  }
}
