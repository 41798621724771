/* ==========================================================================
   #NEW_ISSUE_V2
   ========================================================================== */

.c-new-issue-v2 {
  text-align: center;
}

.c-new-issue-v2__inner {
  display: inline-block;
  padding: 40px;

  background-color: #fff;
  border: 1px solid rgba(54, 49, 139, .2);
  border-radius: $global-radius;
}

.c-new-issue-v2__body {
  margin-bottom: 42px;
}

.c-new-issue-v2__content {
  margin-bottom: 21px;
  color: $color-primary;
}

.c-new-issue-v2__title {
  font-size: 20px;
  line-height: 24px;
}

.c-new-issue-v2__title:not(:last-child) {
  margin-bottom: 3px;
}

.c-new-issue-v2__subtitle {
  font-size: 30px;
  line-height: 32px;
}

.c-new-issue-v2__figure {
  position: relative;

  display: block;
  padding: 9px;
}

.c-new-issue-v2:before,
.c-new-issue-v2__figure:before {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;

  content: "";
  display: block;
  width: 289px;
  height: 237px;

  background: url(../img/new-issue-v2-bg-mobile.svg) scroll no-repeat center center;
  background-size: contain;

  transform: translate(-50%, -50%);
}

.c-new-issue-v2:before {
  display: none;
}

.c-new-issue-v2__figure:after {
  position: absolute;
  z-index: 3;
  left: 50%;
  bottom: -17px;

  content: attr(data-issue-number);
  display: block;
  width: 96px;
  height: 96px;
  line-height: 92px;

  font-weight: 700;
  font-size: 36px;

  color: #fff;

  text-align: center;

  background: url(../img/new-issue-v2-number-bg.svg) scroll no-repeat center center;
  background-size: 100% 100%;

  border-radius: 50%;
  box-shadow: 0 20px 50px rgba(0, 0, 0, .3);

  transform: translateX(-50%);
}

.c-new-issue-v2__picture {
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: top;
  //width: 217px;
  //max-width: 100%;
  box-shadow: 0 20px 50px rgba(0, 0, 0, .3);
}

/* Responsive
   ========================================================================== */

.c-new-issue-v2__previous-issues {
  display: none;
}

@include mq($from: 480px){
  .c-new-issue-v2__inner {
    max-width: 400px;
  }
}

@include mq($from: tablet) {

  .c-new-issue-v2 {
    position: relative;
    z-index: 10;

    padding-top: 150px;
    padding-bottom: 150px;
    text-align: left;
  }

  .c-new-issue-v2:before,
  .c-new-issue-v2__figure:before {
    background-image: url(../img/new-issue-v2-bg-dark-desktop.svg);
  }

  .c-new-issue-v2__figure:before {
    display: none;
  }

  .c-new-issue-v2:before {
    display: block;
    z-index: 1;
    width: 717px;
    height: 570px;
    left: 77%;
  }

  .c-new-issue-v2__inner {
    position: relative;
    z-index: 2;
    max-width: none;
    display: block;
    // padding: 50px;
  }

  .c-new-issue-v2__body {
    margin-bottom: 0;
    margin-right: 230px;
  }

  .c-new-issue-v2__figure {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);

    padding: 0;
  }

  .c-new-issue-v2__picture {
    width: 230px;
  }

  .c-new-issue-v2__title {
    font-size: 30px;
    line-height: 38px;
  }

  .c-new-issue-v2__subtitle {
    font-size: 40px;
    line-height: 50px;
  }

}

@include mq($from: desktop) {

  .c-new-issue-v2__inner {
    padding: 50px;
  }

  .c-new-issue-v2__body {
    margin-right: 400px;
  }

  .c-new-issue-v2__figure {
    right: auto;
    left: 55%;
  }

  .c-new-issue-v2:before {
    left: 70%;
  }

  .c-new-issue-v2__figure:after {
    left: 0;
    bottom: auto;
    top: 50%;

    width: 132px;
    height: 132px;
    line-height: 128px;

    font-size: 50px;

    transform: translate(-50%, -50%);

    transition: $global-transition;
  }

  .c-new-issue-v2__figure:hover:after {
    transform: translate(-50%, -50%) scale(1.1);
  }

  .c-new-issue-v2__picture {
    width: auto;
  }

  .c-new-issue-v2__content {
    margin-bottom: (21px + 63);
  }
}

@include mq($from: wide){

  .c-new-issue-v2__figure {
    left: 490px;
  }

  .c-new-issue-v2:before {
    left: 50%;
  }

  .c-new-issue-v2__previous-issues {
    display: block;
    position: absolute;
    left: 860px;
    top: 50%;
    transform: translateY(-50%);

    white-space: nowrap;

    font-size: 0;
  }

  .c-new-issue-v2__previous-issue {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;

    text-align: center;
  }

  .c-new-issue-v2__previous-issue:not(:first-child) {
    margin-left: 40px;
  }

  .c-new-issue-v2__previous-picture {
    margin-bottom: 15px;
  }

  .c-new-issue-v2__previous-title {
    display: block;
    vertical-align: top;
    transform-origin: 50% 0;
  }

  .c-new-issue-v2__previous-title,
  .c-new-issue-v2__previous-picture {
    transition: $global-transition;
  }

  .c-new-issue-v2__previous-issue:hover .c-new-issue-v2__previous-title {
    color: #1ae5be;
    transform: scale(1.2);
  }

  .c-new-issue-v2__previous-issue:hover .c-new-issue-v2__previous-picture {
    box-shadow: 0 0 0 4px #fff, 0 0 0 8px #1ae5be;
  }

}

