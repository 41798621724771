/* ==========================================================================
   #FANCY_CARDS
   ========================================================================== */

.c-fancy-cards__item {
  margin-bottom: 20px;
}

@include mq($from: tablet){
  .c-fancy-cards__item {
    margin-bottom: 30px;
  }
}
