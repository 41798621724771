/* ==========================================================================
   #MEMBER_MENU
   ========================================================================== */

.c-member-menu .o-menu__item {
  display: inline-block;
}

.c-member-menu .o-menu__link {
  position: relative;
  display: inline-block;

  font-weight: 700;
  font-size: 16px;

  padding-left: 2.5em;
}

.c-member-menu .o-menu__icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-top: -1px;
}
