/* ==========================================================================
   #ALERT
   ========================================================================== */

.c-alert {
  display: block;
  padding: 13px 20px;
  margin-bottom: 32px;


  border-radius: $global-radius;

  color: $color-primary;
  background-color: rgba($color-primary, .1);
}

.c-alert > *:last-child {
  margin-bottom: 0;
}

/* Success
   ========================================================================== */

.c-alert--success {
  color: #0c9100;
  background-color: #93ff8a;
}


/* Danger
   ========================================================================== */

.c-alert--danger {
  color: #db0000;
  background-color: #ff9d9d;
}
