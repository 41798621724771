/* ==========================================================================
   #COMMENT
   ========================================================================== */

$size: 226px;

.c-comment {

}

.c-comment__figure {
  margin-bottom: -32px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: $size;

  top: -10px;
  left: -14px;
}

.c-comment__image,
.c-comment__figure:before {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;

  display: block;
  width: 100%;
  height: 100%;
}

picture.c-comment__figure:after {
  padding-bottom: 100%;
  content: "";
  display: block;
}

.c-comment__image {
  padding: 4px;
}

.c-comment__figure:before {
  z-index: 2;

  content: "";
  display: block;

  background: url(../img/comment-mask-white.svg) no-repeat scroll 0 0;
  background-size: 100% 100%;
}

.c-comment--gray .c-comment__figure:before {
  background-image: url(../img/comment-mask-gray.svg);
}

.c-comment__body {
  position: relative;
  z-index: 10;
}

.c-comment__title {
  margin-bottom: 9px;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2;
  color: $color-secondary;
}

.c-comment__comment {
  // margin-bottom: 10px;
  margin-bottom: 26px;

  font-size: 16px;
  line-height: 22px;
}

.c-comment__author {
  font-weight: 700;
}

.c-comment__body {
  position: relative;

  padding-top: 50px;
}

.c-comment__body:before {
  position: absolute;
  left: -3px;
  top: -2px;

  content: "“";
  display: inline-block;
  vertical-align: top;

  font-weight: 700;
  font-size: 100px;
  line-height: 1;

  color: $color-secondary;
}

/* Center
   ========================================================================== */

@include mq($until: tablet){
  .c-comment--mobile-improved {
    text-align: center;
  }
}

/* Responsive
   ========================================================================== */

@include mq($from: tablet) {
  .c-comment__body {
    margin-left: 40%;
  }

  .c-comment__figure {
    position: relative;
    top: 20px;
    left: 0;

    width: 36%;

    float: left;
  }

  .c-comment__body {
    padding-top: 100px;
  }

  .c-comment__body:before {
    left: -9px;
    top: -9px;
    font-size: 200px;
  }

  .c-comment__title {
    margin-bottom: 32px;
    font-size: 30px;
    line-height: 1;
  }

  .c-comment__comment {
    font-size: 18px;
    line-height: 28px;
  }
}

@include mq($from: wide) {
  .c-comment {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .c-comment__body {
    margin-left: (460px + 120);
  }

  .c-comment__figure {
    top: -10px;
    left: -23px;

    width: 500px;
  }
}
