/* ==========================================================================
   #PAGE_HEADER */

$back-button-size-mobile: 24px;
$back-button-size-desktop: 30px;

.c-page-header {
  position: relative;
  z-index: 10;
  color: #fff;

  height: 350px;

  overflow: hidden;
  background: linear-gradient(93.21deg, #18bffe -42.78%, #191b70 57.8%, #04063b 146.54%);
}

.c-page-header:before,
.c-page-header:after {
  position: absolute;
  z-index: 6;

  content: "";

  left: -30px;
  top: -75px;

  width: 198px;
  height: 208px;

  background: url(../img/decoration.svg) scroll no-repeat 0 0;
  background-size: 100% 100%;

  opacity: .03;
}

.c-page-header:after {
  left: auto;
  top: auto;

  right: -30px;
  bottom: -50px;

  width: 276px;
  height: 290px;
}

.c-page-header__body {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;

  width: 100%;

  padding-bottom: 22px;
}

.c-page-header__figure,
.c-page-header__figure .c-responsive-image {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;

  display: block;
  width: 100%;
  height: 100%;
}

.c-page-header__figure {
  opacity: .6;
}

.c-page-header__actions {
  display: none;
}

.c-page-header__heading {
  margin-bottom: 0;
  font-size: 30px;
  line-height: 40px;
  color: inherit;
}

.c-page-header__breadcrumb {
  position: relative;

  margin-bottom: 21px;

  padding-bottom: 4px;

  border-bottom: 1px solid rgba(#fff, .5);
}

/* Responsive
   ========================================================================== */

@include mq($from: tablet){
  .c-page-header__toolbar {
    position: relative;
  }

  .c-page-header__actions {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    display: block;
  }

  .c-page-header__breadcrumb {
    padding-bottom: 12px;
  }

  .c-page-header .c-breadcrumbs__back:hover {
    background-color: #fff;
    color: $color-primary;
  }

  .c-page-header .c-breadcrumbs__back:hover:before {
    border-color: transparent;
  }

}

@include mq($from: desktop){
  .c-page-header__heading {
    font-size: 54px;
    line-height: 1.2;
  }

  .c-page-header__action.c-btn {
    padding: 16px 38px;
    font-size: 20px;
  }
}

@include mq($from: wide){

  .c-page-header {
    height: 560px;
  }


  .c-page-header__body {
    padding-bottom: 67px;
  }

  .c-page-header__breadcrumb {
    margin-bottom: 61px;
  }

}
