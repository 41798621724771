/* ==========================================================================
   #TITLE
   ========================================================================== */

.c-title {
  margin-bottom: 8px;
}

@include mq($from: tablet){
  .c-title {
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 60px;
  }
}

