/* ==========================================================================
   #ICON_LINK
   ========================================================================== */

.c-icon-link {
  position: relative;
  display: inline-block;

  font-weight: 700;
  font-size: 18px;

  padding-left: 2em;
}

.c-icon-link__icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  display: inline-block;
  width: 1.5em;
  height: 1.5em;
}

/* Responsive
   ========================================================================== */

@include mq($from: tablet){
  .c-icon-link {
    font-size: 22px;
    line-height: 28px;
  }
}
