/**
 * Responsive Video
 */

.c-responsive-video {
  position: relative;
  z-index: 1;
}

.c-responsive-video .c-responsive-image {
  position: relative;
  z-index: 1;
}

.c-responsive-video__holder {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-responsive-video__video {
  //position: absolute;
  //left: 50%;
  //top: 0;
  //width: auto;
  //height: 100%;
  //
  //transform: translateX(-50%);

  display: block;
  min-width: 100%;
  object-fit: cover;
}
